.notSupported {
  pointer-events: none;
  user-select: none;
  transition: height 0.25s ease-in-out;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  /* stylelint-disable-next-line color-function-notation */
  color: hsl(from var(--hubble-ui-background-tertiary-1) h calc(s - 5) calc(l - 35));
  text-shadow: 0 1px 0 white;

  & > div {
    max-width: 640px;
  }

  & > div > img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .explanation {
    font-size: 70%;
    margin-top: 10px;
  }
}
