@use 'sass:math';

@import '../../styles/common/core';
@import '../../styles/common/layout';

$tooltip-color: #343e44;
$tooltip-arrow-diag: 25px;
$tooltip-arrow-size: math.div($tooltip-arrow-diag, 1.41);

.tooltipBody {
  @include column;
  color: white;

  &:focus {
    outline: none;
  }

  .tooltipContent {
    z-index: 10;
    background: $tooltip-color;

    border-radius: 4px;
    padding: 10px;
  }

  .tooltipArrow {
    align-self: center;
    background: $tooltip-color;
    border-radius: 2px;

    width: $tooltip-arrow-size;
    height: $tooltip-arrow-size;

    position: relative;
    bottom: $tooltip-arrow-size * 0.5 + 2px;
    margin-bottom: -$tooltip-arrow-size * 0.5 + 1px;
    z-index: -1;

    transform: rotate(45deg);
  }
}
