@import '../../styles/common/layout';

$handle-height: 23px;
$dot-w: 2px;
$dot-h: 2px;

.resizeHandle {
  @include row(center, center);
  @include exact-size(default, $handle-height);

  user-select: none;
  transition: background 0.5s;
  background: var(--hubble-ui-resize-handle-bg);

  &:hover {
    background: var(--hubble-ui-resize-handle-bg-hover);
  }

  .dots {
    .topLine,
    .bottomLine {
      @include row(flex-start, center);

      .dot {
        @include exact-size($dot-w, $dot-h);
        background: var(--hubble-ui-resize-handle-dot-bg);
      }

      .dot:not(:last-child) {
        margin-right: $dot-w;
      }
    }

    .bottomLine {
      margin-top: $dot-h;
    }
  }

  &.horizontal {
    cursor: ns-resize;
  }

  &.vertical {
    cursor: ew-resize;
  }

  &.stickToTop {
    position: sticky;
    top: 0;
  }
}
