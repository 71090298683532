.serviceIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  &.kafka {
    img {
      width: auto;
      height: 100%;
    }
  }
}
