@import '../../../styles/common/layout';
@import '../../../styles/common/core';

.podSelect {
  margin-top: 20px;

  .header {
    // margin-top: 12px;
    font-weight: 500;
    font-size: 13px;
    color: var(--hubble-ui-text-secondary);

    margin-bottom: 10px;
  }
}

.wrapper {
  width: 100%;

  :global(.bp5-button-text) {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.popover {
  width: 100%;
}
