@import './blueprint';
@import './styles/common/core';
@import './styles/common/themes';

:global {
  html,
  body {
    font-family:
      Inter,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen-Sans,
      Ubuntu,
      Cantarell,
      'Helvetica Neue',
      sans-serif;
    margin: 0;
    padding: 0;

    // TODO: why is this?
    font-size: 16px;

    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .light {
    @include light-theme;
  }

  .dark {
    @include dark-theme;
  }

  body {
    background-color: var(--hubble-ui-background-secondary);
  }

  #app {
    width: 100%;
    height: 100%;
  }

  #app > div[tabindex='-1'] {
    min-height: 100%;
    height: 100%;
  }
}
