.container {
  position: relative;
}

.filterTagButton {
  align-items: center;
  background: var(--hubble-ui-searchbar-filter-bg);
  border-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: 0.3rem 0.3rem;
  min-height: 26px;
  padding: 0.05rem 2rem 0.05rem 0.3rem;

  &:hover {
    background-color: var(--hubble-ui-searchbar-filter-bg-hover);
    cursor: pointer;
  }

  &:focus {
    background-color: var(--hubble-ui-searchbar-filter-bg-focus);
  }
}

.removeFilterTagButton {
  background: transparent;
  border: none;
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
