@import '~/styles/common/layout';

$processes-indent: 25px;
$connector-icon-size: 22px;

.podHandle {
  @include column;

  width: min-content;
  height: min-content;

  .headline {
    @include row(flex-start, center);

    color: var(--hubble-ui-ptree-text-primary);
    font-size: 12px;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;

    .nodeName {
      @include row(flex-start, center);

      .podConnectorIcon {
        @include exact-size($connector-icon-size, $connector-icon-size);
        margin-right: 5px;

        img {
          width: 100%;
        }
      }

      span {
        white-space: nowrap;
      }
    }

    & > div:not(.nodeName) {
      @include row(flex-start, center);

      img {
        margin-right: 5px;
      }

      span {
        white-space: nowrap;
      }
    }

    .nodeName img {
      width: 22px;
    }

    .namespaceName img {
      width: 16px;
    }

    .podName img {
      width: 20px;
    }

    .separator {
      margin: 0 10px;
      width: 8px;
    }
  }

  .processes {
    @include column;

    margin-left: $processes-indent;
  }
}
