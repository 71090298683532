.kind {
  color: var(--hubble-ui-searchbar-filter-tag-kind);
  font-weight: 500;
}

.separator {
  color: var(--hubble-ui-searchbar-filter-tag-separator);
}

.query {
  color: var(--hubble-ui-searchbar-filter-tag-query);
}
