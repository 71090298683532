@import '../../../styles/common/layout';

$icon-max-size: 28px;

.egressGroup {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  @include column;

  .headline {
    @include row(flex-start, center);

    .icon {
      @include exact-size($icon-max-size, $icon-max-size);
    }

    .captions {
      @include column(flex-start, flex-start);
      margin-left: 10px;

      .caption {
        font-weight: bold;
        font-family: 14px;

        white-space: nowrap;
      }

      .labels {
        @include ellipsis-limit;

        font-family: 'Roboto Mono', monospace;
        font-size: 12px;
        color: var(--hubble-ui-ptree-text-tertiary);

        margin-top: 2px;
        max-width: 400px;
      }
    }
  }

  .endpoints {
    margin-top: 10px;

    & > .endpoint:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &.clickable {
    .headline .captions .caption {
      cursor: pointer;
    }
  }
}

.endpoint {
  @include row(flex-start, center);
  font-size: 11px;

  .port {
    color: var(--hubble-ui-ptree-endpoint-port);
    font-weight: 800;
  }

  .protocol {
    color: var(--hubble-ui-ptree-text-secondary);
    font-weight: 700;

    margin: 0 5px;
  }

  .ipAddress {
    margin-left: 5px;
    color: var(--hubble-ui-ptree-text-secondary);
  }

  &.clickable {
    cursor: pointer;
  }
}

.endpointGroup {
  @include column;
  font-size: 11px;

  .handle {
    @include row;
    @include ellipsis-limit;

    .collapsedLine {
      @include ellipsis-limit;
      flex-grow: 0;

      .port {
        &:not(:last-child) {
          margin-right: 5px;
        }

        color: var(--hubble-ui-ptree-endpoint-port);
        font-weight: 700;
      }

      .portsEllipsis {
        letter-spacing: 0.5;
      }
    }

    .expandControls {
      flex-grow: 0;
      flex-shrink: 0;

      padding-left: 10px;
      padding-right: 5px;

      text-decoration: underline;
      cursor: pointer;
      color: var(--hubble-ui-ptree-expand-ctrl);
      font-weight: 600;
    }
  }

  & > .endpoints:nth-of-type(1) {
    margin-top: 0;
  }
}
