.ruleInlineHighlight {
  background: var(--hubble-ui-yamleditor-highlight-bg);
}

.diffWrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

:global {
  .react-monaco-editor-container {
    .view-line * {
      font-family: monospace !important;
    }

    .cursor {
      visibility: hidden !important;
    }
  }
}
