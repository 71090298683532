@import '../../styles/common/layout';

.app {
  display: flex;

  width: 100%;
  height: 100%;

  .sidebar {
    flex-shrink: 0;
    width: var(--sidebar-width);
  }

  .workspace {
    @include column;
    flex-grow: 1;

    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;
    background-color: var(--hubble-ui-dashboard-bg);
  }
}
