.wrapper {
  height: 100%;
  overflow: auto;
  padding: 0 17px 0 10px;

  &.promo,
  &.help {
    min-width: 340px;
  }

  &.promo {
    .image {
      width: 86.21px;
      height: 61.21px;
    }

    .title {
      margin-top: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: var(--hubble-ui-hubblepanel-promo-title);
    }

    .description {
      margin-top: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--hubble-ui-hubblepanel-promo-descr);
    }

    .actions {
      display: flex;
      margin: 18px 0;
    }
  }

  &.help {
    .text {
      margin: 0 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--hubble-ui-hubblepanel-help);
    }

    .hubbleObserveCmd {
      position: relative;
      display: flex;
      margin-bottom: 7px;
      padding: 3px 17px 3px 7px;
      background: var(--hubble-ui-hubblepanel-help-cmd-bg);
      border-radius: 4px;
      font-family: 'Roboto Mono', monospace;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--hubble-ui-hubblepanel-help-cmd);
      text-decoration: underline;

      .copyIcon {
        position: absolute;
        top: 7px;
        right: 13px;
      }
    }

    .actions {
      display: flex;
      margin-bottom: 18px;
    }
  }

  &.flows {
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-shrink: 0;
      height: 40px;
      padding: 0 0 8px 8px;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .right {
        justify-content: flex-end;
      }

      .title {
        margin-right: 20px;
        font-size: 14px;
        line-height: 18px;
        color: var(--hubble-ui-hubblepanel-flows-title);
      }

      .close {
        float: right;
      }

      .actions {
        display: flex;
        align-items: center;
      }
    }

    .tableCaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;

      :global(.bp5-button.bp5-minimal) {
        min-height: 30px;
        min-width: 31px;
      }

      :global(.bp5-icon.bp5-icon-cog) {
        color: var(--hubble-ui-bp-icon-cog);
      }
    }

    .liveViewSwitcher {
      font-size: 14px;
      font-weight: 500;
    }

    .table {
      width: 100%;
      height: calc(100% - 50px);
      border-radius: 10px;
      border: 1px solid var(--hubble-ui-hubblepanel-flowstable-border);
      overflow: hidden;
      flex: 1;
    }
  }

  .uploadButton {
    margin-right: 10px;
  }
}
