.inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.directionIcon {
  width: 10px;
  margin-left: 3px;
}
