.wrapper {
  width: 100%;
  height: 100vh;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.namespaceBackplate {
  rect {
    stroke-dasharray: 10px 10px;
    stroke-width: 2px;
  }

  text {
    font-size: 1.75em;
    font-weight: 700;
  }
}
