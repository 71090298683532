@import '../../styles/common/prelude';

$icon-size: 20px;
$circle-size: 8px;
$preview-border-radius: 5px;

.statusCenterHandle {
  @include row(flex-start);
  flex-grow: 1;

  color: var(--hubble-ui-text-secondary);

  &.hasNotifications {
    .regularHandle {
      .icon {
        .notificationsCircle {
          opacity: 1;
        }
      }
    }
  }

  .regularHandle {
    @include row(flex-start);
    flex-grow: 1;

    padding: 10px 0;
    border-bottom: 1px solid var(--hubble-ui-statuscenterhandle-border);
    margin: 10px 0;

    cursor: pointer;

    .icon {
      @include exact-size($icon-size, $icon-size);
      margin-right: 10px;
      position: relative;

      .notificationsCircle {
        @include exact-size($circle-size, $circle-size);

        position: absolute;
        right: 0;
        top: 0;

        transition: all 0.15s;
        opacity: 0;
      }
    }

    .caption {
      color: var(--hubble-ui-statuscenterhandle-caption);
      margin-right: 10px;
    }
  }

  .criticalHandle {
    @include row(flex-start, center);
    flex-grow: 1;
    max-width: 100%;

    padding: 10px;
    margin: 10px 0;
    cursor: pointer;

    border: 1px solid var(--hubble-ui-statuscenterhandle-critical-border);
    border-radius: $preview-border-radius;

    background: var(--hubble-ui-statuscenterhandle-critical-bg);
    color: var(--hubble-ui-statuscenterhandle-critical-text);

    .spinner {
      margin-right: 5px;
    }

    .title {
      @include ellipsis-limit;
      @include font-inter(500, 14px);
    }
  }

  // .arrowIcon {}
}
