@import '../../styles/common/layout';
@import '../../styles/common/vars';

.layout {
  @include row(flex-start, center);
  font-size: 14px;
  padding-right: 10px;

  :global(.bp5-button.bp5-minimal.bp5-small) {
    background: none;
    border: none;
    box-shadow: none;
  }

  :global(.bp5-icon.bp5-icon-chevron-down) {
    color: var(--hubble-ui-bp-icon-chevron-down);
  }
}

.button {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  &:global(.bp5-button .bp5-icon) {
    color: #919cac;
  }
}

.icon_smaller {
  svg {
    transform: scale(0.9);
  }
}

.icon_bigger {
  svg {
    transform: scale(1.1);
  }
}

.hidden {
  opacity: 0;
}
