@import '../../styles/common/layout';
@import '../../styles/common/core';

.processEventTableSidebar {
  @include column;
  @include exact-size(320px);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 150;

  padding: 10px;

  background: var(--hubble-ui-ptree-eventstable-sidebar-bg);
  border-left: 1px solid var(--hubble-ui-ptree-eventstable-sidebar-border);
  box-shadow: 0 22px 22px var(--hubble-ui-ptree-eventstable-sidebar-boxshadow);

  overflow-y: scroll;

  .header {
    @include row(space-between, center);
    position: relative;
    margin-bottom: 13px;

    font-size: 14px;
    font-weight: 600;
    color: var(--hubble-ui-ptree-eventstable-sidebar-header);

    .closeButton {
      position: fixed;
      color: var(--hubble-ui-ptree-eventstable-sidebar-close-btn);
      right: 36px;

      &:hover {
        cursor: pointer;
        color: var(--hubble-ui-ptree-eventstable-sidebar-close-btn-hover);
      }
    }
  }

  & > .section:not(.general) {
    margin-top: 20px;
  }

  .section {
    @include column;

    & > .caption {
      color: var(--hubble-ui-ptree-eventstable-sidebar-section-caption);

      font-weight: 700;
      font-size: 13px;

      margin-bottom: 7px;
    }

    .labels {
      & > .descriptor:not(:last-child) {
        margin-bottom: 10px;
      }

      & > .descriptor {
        @include column;
        font-size: 12px;

        .caption {
          color: var(--hubble-ui-ptree-eventstable-sidebar-labels-caption);

          font-weight: 700;
        }

        .content {
          // @include ellipsis-limit();
          max-width: 100%;
          word-break: break-all;
          overflow: hidden;

          color: var(--hubble-ui-ptree-eventstable-sidebar-labels-content);
        }
      }
    }
  }

  .labels {
    @include column;
  }
}
