@import '../../styles/common/layout';
@import '~@blueprintjs/core/lib/scss/variables';

.baseCard {
  // NOTE: display: flex is required to count margins for offsetHeight
  @include column;

  margin: var(--endpoint-shadow-size);
  border-radius: 10px;
  user-select: none;

  transition:
    transform ($pt-transition-duration * 2) $pt-transition-ease,
    box-shadow ($pt-transition-duration * 2) $pt-transition-ease;

  :global(button.bp5-button) {
    margin: 7px 20px 14px;
  }

  &:global(.clickable:hover) {
    box-shadow: var(--hubble-ui-card-boxshadow-hover);
    border: 1px solid rgba(25, 109, 211, 0.1);
    cursor: pointer;
  }
}
