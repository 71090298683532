.wrapper {
  position: relative;
  max-height: 40vh;
  overflow: auto;
  border-radius: 0 0 3px 3px;

  table {
    width: 100%;
    border: none;

    caption {
      position: sticky;
      top: 0;
      height: 25px;
      text-align: left;
      font-size: 14px;
      line-height: 25px;
      font-weight: 700;
      padding: 0 20px 5px;
      border-bottom: 1px solid var(--hubble-ui-dashboard-hubblestatus-border);
      background-color: var(--hubble-ui-dashboard-bg);

      .captionInner {
        display: flex;
        justify-content: space-between;
      }
    }

    thead {
      tr {
        background-color: var(--hubble-ui-dashboard-bg);
      }

      th {
        position: sticky;
        top: 25px;
        height: 20px;
        line-height: 20px;
        text-align: left;
        color: var(--hubble-ui-dashboard-hubblestatus-head);
        font-size: 12px;
        font-weight: 500;
        background-color: var(--hubble-ui-dashboard-bg);
      }
    }

    tbody {
      tr {
        td {
          vertical-align: top;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          background-color: var(--hubble-ui-background-secondary);
        }

        &:first-child {
          td {
            padding-top: 5px;
          }

          td:first-child {
            border-top-left-radius: 3px;
          }

          td:last-child {
            border-top-right-radius: 3px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 5px;
          }

          td:first-child {
            border-bottom-left-radius: 3px;
          }

          td:last-child {
            border-bottom-right-radius: 3px;
          }
        }
      }
    }

    td,
    th {
      padding: 2px 5px;
    }

    td:first-child,
    th:first-child {
      padding-left: 20px;
    }

    td:last-child,
    th:last-child {
      padding-right: 20px;
    }

    .enabled {
      color: var(--hubble-ui-dashboard-hubblestatus-enabled);
    }

    .disabled {
      color: var(--hubble-ui-dashboard-hubblestatus-disabled);
    }

    .muted {
      color: var(--hubble-ui-dashboard-hubblestatus-muted);
    }
  }

  .noTextWrap {
    white-space: pre;
  }

  .leftArea {
    display: flex;
  }

  .hubbleNodesMiniInfo {
    margin-left: 5px;
  }

  .loadingArea {
    margin-left: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--hubble-ui-text-secondary);
    font-weight: 400;

    div {
      margin-left: 5px;
    }
  }

  .hubbleFlowsMiniInfo {
    font-weight: 400;
    font-size: 12px;
    color: var(--hubble-ui-dashboard-hubblestatus-mini-info);
  }
}

.alignedRight {
  text-align: right;
  justify-content: flex-end;
}
