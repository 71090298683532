// C O L O R S
// According to INTERFACES figma template

$black: #303030;
$gray1: #7b7b7b;
$gray2: #979797;
$gray3: #c2c2c2;
$gray4: #d7d7d7;
$gray5: #e4e4e4;
$white: #fff;
$orange: #d17a31;
$green: #54a82d;
$red: #d46363;
$blue: #2697e9;
$blue-hilight: #147fbc;

// S I Z E S   A N D   M E T R I C S
$default-padding: 20px;

// TODO: Create UI color system from themes.scss
$color-white: #fff;
$color-black: #000;
$color-green: #54a82d;
$pt-elevation-shadow-0: 0 0 0 1px rgba(17, 20, 24, 0.15) !default;
$pt-elevation-shadow-1:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 1px 1px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-2:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 1px 1px rgba(17, 20, 24, 0.2),
  0 2px 6px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-3:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-4:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 4px 8px rgba(17, 20, 24, 0.2),
  0 18px 46px 6px rgba(17, 20, 24, 0.2) !default;
$pt-dark-elevation-shadow-0: inset 0 0 0 1px rgba(255, 255, 255, 0.2) !default;
$pt-dark-elevation-shadow-1:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 1px 1px 0 rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-2:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 1px 1px rgba(17, 20, 24, 0.2 * 2),
  0 2px 6px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-3:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-4:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 4px 8px rgba(17, 20, 24, 0.2 * 2),
  0 18px 46px 6px rgba(17, 20, 24, 0.2 * 2) !default;
