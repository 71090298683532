@import '../../../styles/common/layout';
@import '../../../styles/common/core';

.processEventRow {
  @include row(flex-start, center);

  border-bottom: 1px solid var(--hubble-ui-ptree-eventstable-row-border-bottom);
  color: var(--hubble-ui-ptree-text-primary);

  min-height: 25px;
  cursor: pointer;

  &:hover {
    background: var(--hubble-ui-ptree-eventstable-row-bg-hover);
  }

  &.header {
    border-bottom: none;
    color: var(--hubble-ui-ptree-text-secondary);
    font-weight: 600;
  }

  & > div {
    @include row(flex-start, center);

    span {
      @include ellipsis-limit;
    }

    font-size: 12px;
    padding: 0 10px;
  }

  .nodeName {
    @include exact-size(100px);
  }

  .time {
    @include exact-size(180px);
  }

  .kind {
    @include exact-size(75px);
  }

  .pid {
    @include exact-size(75px);
  }

  .binary {
    @include exact-size(170px);
  }

  .flags {
    @include exact-size(100px);
  }

  .args {
    @include exact-size(250px);
  }

  .startTime {
    @include exact-size(180px);
  }

  .sourceIp {
    @include exact-size(100px);
  }

  .sourcePort {
    @include exact-size(100px);
  }

  .destinationIp {
    @include exact-size(100px);
  }

  .destinationPort {
    @include exact-size(115px);
  }

  .destinationNames {
    @include exact-size(150px);
  }
}
