@import '../../../styles/common/layout';

$text-size: 12px;
$connector-circle-size: 7px;
$sub-tree-indent: 22px;
$container-icon-size: 16px;
$host-icon-width: 13px;

.processesSubTree {
  @include column;

  min-width: min-content;
  min-height: min-content;

  .rootHandle {
    @include row(flex-start, center);
    font-family: 'Roboto Mono', monospace;

    min-height: 22px;

    .connectorCircle {
      @include exact-size($connector-circle-size, $connector-circle-size);
      margin-right: 10px;

      border-radius: 50%;
      background: var(--hubble-ui-ptree-dot);
    }

    .containerIcon {
      @include row(flex-start, center);
      @include exact-size($container-icon-size);

      margin-right: 5px;

      /* NOTE: to prevent DOM from triggering MutationObserver */
      overflow: hidden;

      img {
        width: $container-icon-size;
      }
    }

    .processData {
      @include row(flex-start, center);

      font-size: $text-size;
      font-family: 'Roboto Mono', monospace;
      color: var(--hubble-ui-ptree-text-primary);

      .timeDiff {
        margin-right: 5px;
        color: var(--hubble-ui-ptree-text-tertiary);
        font-style: oblique;

        white-space: nowrap;
      }

      .executable {
        @include ellipsis-limit;

        font-weight: bold;
        max-width: 200px;

        color: var(--hubble-ui-ptree-text-primary);
      }

      .pids {
        font-weight: bold;
        color: var(--hubble-ui-ptree-text-primary);

        .pid,
        .hostPid {
          margin-right: 5px;
        }
      }

      .flags {
        @include ellipsis-limit;
        max-width: 300px;
        margin-left: 5px;

        color: var(--hubble-ui-ptree-text-tertiary);
      }

      .hostIcon {
        @include centered;
        @include exact-size($host-icon-width);

        margin-left: 5px;

        img {
          width: 100%;
        }
      }

      .trafficConnector {
        @include centered;
        margin-left: 5px;

        .icon {
          @include centered;

          img {
            width: 16px;
          }
        }
      }
    }

    .containerData {
      @include row(flex-start, center);
      font-size: $text-size;
      font-family: 'Roboto Mono', monospace;
      font-weight: bold;

      .containerName {
        /* color: black; */
      }

      .startTime {
        margin-right: 10px;
        color: var(--hubble-ui-ptree-text-tertiary);
      }
    }
  }

  &.insideContainer {
    .rootHandle {
      .processData {
        color: var(--hubble-ui-ptree-text-primary);

        .executable {
          color: var(--hubble-ui-ptree-text-primary);
        }

        .pids {
          font-weight: normal;
          color: var(--hubble-ui-ptree-text-primary);
        }

        .flags {
          color: var(--hubble-ui-ptree-text-tertiary);
        }
      }
    }
  }

  &.namespacedInit {
    & > .rootHandle {
      .connectorCircle {
        background: var(--hubble-ui-ptree-dot);
      }

      .processData {
        color: var(--hubble-ui-ptree-container-text);

        .timeDiff {
          color: var(--hubble-ui-ptree-container-text-secondary);
        }

        .executable {
          color: var(--hubble-ui-ptree-container-text);
        }

        .pids {
          font-weight: bold;
          color: var(--hubble-ui-ptree-container-text);
        }

        .flags {
          color: var(--hubble-ui-ptree-container-text-secondary);
        }
      }
    }
  }

  &.suspicious {
    & > .rootHandle {
      .connectorCircle {
        background-color: var(--hubble-ui-ptree-dot-sus);
      }

      .processData {
        color: var(--hubble-ui-ptree-sus-text);

        .timeDiff {
          color: var(--hubble-ui-ptree-sus-text-secondary);
        }

        .executable {
          color: var(--hubble-ui-ptree-sus-text);
        }

        .pids {
          color: var(--hubble-ui-ptree-sus-text);
        }

        .flags {
          color: var(--hubble-ui-ptree-sus-text-secondary);
        }
      }
    }
  }

  .subTree {
    margin-left: $sub-tree-indent;
  }
}
