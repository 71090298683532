@import '../../../styles/common/layout';

.processTreeArrows {
  .arrowFromNodeToRootProcess {
    fill: none;
  }

  .processesArrows {
    fill: none;
  }

  .toContainerArrows {
    fill: none;
  }

  .toChildGroupsArrows {
    fill: none;
  }
}
