@import '../../styles/common/layout';

.wrapper {
  display: block;
  width: 100%;
  height: 100vh;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.namespaceBackplate {
  rect {
    fill: var(--hubble-ui-svcmap-namespacebackplate-rect-fill);
    stroke: var(--hubble-ui-svcmap-namespacebackplate-rect-stroke);
    stroke-dasharray: 10px 10px;
    stroke-width: 2px;
  }

  text {
    font-size: 1.75em;
    fill: var(--hubble-ui-svcmap-namespacebackplate-text);
    font-weight: 700;
  }
}
