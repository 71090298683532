.outer {
  color: var(--hubble-ui-navsidebar-policies-outer);

  .filtersBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    background: var(--hubble-ui-navsidebar-policies-filtersbanner-bg);
    border-radius: 5px;
    height: 37px;
    color: var(--hubble-ui-navsidebar-policies-filtersbanner-text);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

    .filtersBannerLabel {
      padding-left: 24px;

      // TODO: Keep or remove?
      background: url('/icons/misc/filters.png') no-repeat;
      background-position: left;
      background-size: 13px 9px;
    }
  }

  .inner {
    padding: 10px 8px;
    margin-left: -8px;
    margin-right: -8px;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 10px;

    .switcher {
      margin-top: 10px;
      margin-bottom: 4px;
    }

    .switcherNote {
      margin-bottom: 18px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--hubble-ui-navsidebar-policies-switcher-note);
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: var(--hubble-ui-navsidebar-policies-title);
    }

    .historicalTitle {
      margin-top: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: var(--hubble-ui-navsidebar-policies-title);
    }

    .messageWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-style: italic;

      .messageText {
        margin-top: 9px;
        font-style: italic;
      }
    }

    .list {
      margin-top: 9px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--hubble-ui-navsidebar-policies-item);

        .label {
          margin-left: 6px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:not(.active):hover {
          cursor: pointer;
          color: var(--hubble-ui-navsidebar-policies-item-inactive-hover);
        }

        &.active {
          color: var(--hubble-ui-navsidebar-policies-item-active);
        }
      }
    }

    &.active {
      background: var(--hubble-ui-navsidebar-policies-filtersbanner-bg-active);
      border-color: var(--hubble-ui-navsidebar-policies-filtersbanner-border-active);

      .switcherNote {
        color: var(--hubble-ui-navsidebar-policies-switcher-note-active);
      }
    }
  }
}
