@import '../../../styles/common/layout';

$sub-tree-indent: 22px;
$connector-icon-size: 16px;
$state-arrow-height: 11px;
$text-size: 12px;

.similarProcessesGroup {
  @include column;
  font-size: $text-size;

  min-width: min-content;
  min-height: min-content;

  .rootHandle {
    @include row(flex-start, center);
    font-family: 'Roboto Mono', monospace;

    min-height: 22px;

    .connectorIcon {
      @include row(flex-start, center);
      @include exact-size($connector-icon-size);

      position: relative;
      margin-right: $connector-icon-size * 0.4;

      /* NOTE: to prevent DOM from triggering MutationObserver */
      overflow: hidden;

      img {
        width: $connector-icon-size;
      }
    }

    .amount {
      color: var(--hubble-ui-ptree-text-secondary);
      font-weight: 700;
      margin-right: 5px;
    }

    .stateArrow {
      @include centered;

      cursor: pointer;
      margin-right: 5px;
      transition: all 0.5s;

      img {
        height: $state-arrow-height;
      }
    }

    .executable {
      @include ellipsis-limit;

      font-weight: bold;
      max-width: 200px;

      color: var(--hubble-ui-ptree-text-primary);
    }

    .trafficConnector {
      @include centered;
      margin-left: 5px;

      .icon {
        @include centered;

        img {
          width: 16px;
        }
      }
    }
  }

  &.opened {
    & > .rootHandle > .stateArrow img {
      transform: rotate(90deg);
    }
  }

  &.insideContainer {
    & > .rootHandle {
      color: var(--hubble-ui-ptree-text-primary);

      .executable {
        color: var(--hubble-ui-ptree-text-primary);
      }
    }
  }

  &.suspicious {
    & > .rootHandle {
      color: var(--hubble-ui-ptree-sus-text);

      .executable {
        color: var(--hubble-ui-ptree-sus-text);
      }
    }
  }

  .subTree {
    margin-left: $sub-tree-indent;
  }
}
