.row {
  display: flex;
  flex: 1;
  width: 100%;

  :global(.bp5-button.bp5-minimal) {
    background: none;
    border: none;
    box-shadow: none;
  }

  :global(.bp5-timepicker > .bp5-timepicker-input-row) {
    background: var(--hubble-ui-bp-timepicker-bg);
    border: 1px solid var(--hubble-ui-bp-timepicker-border);
    border-radius: 6px;
    box-shadow: var(--hubble-ui-bp-timepicker-boxshadow);
  }

  :global(.bp5-icon.bp5-icon-chevron-left) {
    color: var(--hubble-ui-bp-icon-chevron);
  }

  :global(.bp5-icon.bp5-icon-chevron-right) {
    color: var(--hubble-ui-bp-icon-chevron);
  }

  :global(.bp5-icon.bp5-icon-double-caret-vertical) {
    color: var(--hubble-ui-bp-icon-caret-vertical);
  }
}

.applyButton {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
