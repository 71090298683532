.popoverWrapper {
  max-width: 100%;
}

.button {
  display: flex;
  max-width: 100%;
  min-width: 0;

  :global(.bp5-icon) {
    display: block;
    flex-shrink: 0;
  }

  :global(.bp5-button-text) {
    display: block;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
