@import '../../styles/common/layout';

.wrapper {
  display: block;
  width: 100%;
  height: 100vh;
  cursor: grab;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  opacity: 1;

  &:active {
    cursor: grabbing;
  }

  &.exit {
    opacity: 0;
    transform: scale(0.5);
  }
}

.outsideCard,
%outside {
  background-color: var(--hubble-ui-svcmap-card-bg-primary) !important;
  box-shadow: 0 0 var(--endpoint-shadow-size) rgba(0, 0, 0, 0.1) !important;
  border: none !important;

  &:hover {
    box-shadow: var(--hubble-ui-card-boxshadow-hover) !important;
    cursor: pointer;
  }
}

.insideCard,
%inside {
  background-color: var(--hubble-ui-svcmap-card-bg-primary) !important;
  border: 2px solid var(--hubble-ui-svcmap-card-border) !important;
}

.insideCardBackground {
  @extend %inside;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 26px;
  align-items: center;

  & > *:first-child {
    margin-inline-start: 25px;
  }
}

.outsideCardBackground {
  @extend %outside;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 26px;
  align-items: center;

  & > *:first-child {
    margin-inline-start: 25px;
  }
}

.clusterOutsideServiceCard {
  background-color: var(--hubble-ui-svcmap-card-bg-primary) !important;
  box-shadow: 0 0 var(--endpoint-shadow-size) rgba(0, 0, 0, 0.1) !important;
}

.connector {
  height: 25px;
}

.icon {
  width: 45px;
}

.breadcrumbs {
  color: var(--hubble-ui-text-secondary);
  font-size: 28px;
  display: flex;
  height: 50px;
  gap: 15px;
  flex-direction: row;
  align-items: center;
  margin: 25px;
  margin-inline-start: 45px;

  & :global(button.bp5-button) {
    font-size: 28px;
    margin: 0;
    margin-inline-start: 10px;
    max-width: 250px;
  }
}

.link {
  color: var(--hubble-ui-text-secondary);
  text-decoration: underline;
  text-wrap: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cluster {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 26px;
  text-align: center;
  color: #91a3b0;
  text-shadow: 3px 3px 0 #fff;

  & > div {
    max-width: 500px;
  }

  & > div.icon {
    margin-top: -90px;
    margin-bottom: -130px;
    width: 200px;
    height: 200px;
    opacity: 0.1;
    background-image: url('/icons/misc/no-data.svg');
  }
}

.arrowsForeground {
  pointer-events: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

.transparent {
  flex-grow: 0;
  background-color: var(--hubble-ui-svcmap-card-bg-primary) !important;
  margin-block: 10px;
  padding-block: 40px;
}

.card-header {
  display: flex;
  flex-direction: row;
}

.highlight {
  filter: drop-shadow(0 0 10px rgb(84 143 181 / 80%));
}
