@import '~/styles/common/layout';
@import '~/styles/common/themes';

$processes-indent: 25px;

.tree {
  @include column;

  .sourcesAndDestinations {
    @include row;
    flex-wrap: nowrap;

    width: fit-content;
    height: fit-content;
    min-width: fit-content;
    min-height: fit-content;

    .egressGroups {
      & > div:not(:last-child) {
        margin-bottom: 23px;
      }
    }

    .podHandles {
      margin-right: 150px;

      & > div:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &:global(.embedded-light) {
    @include light-theme;
  }

  &:global(.embedded-dark) {
    @include dark-theme;
  }
}
