.wrapper {
  display: block;
  padding: 0;
  margin: 0 0 2px -6px;
  border-radius: 8px;

  &:hover {
    background-color: var(--hubble-ui-background-secondary-hover);
  }

  &.active {
    cursor: default;
    background-color: var(--hubble-ui-background-secondary-selected);

    > .clickable {
      cursor: default;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .clickable {
    width: calc(100% + 6px);
    display: flex;
    align-items: center;
    padding: 6px;
    margin: 2px 0;
    border: none;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    color: var(--hubble-ui-text-primary);
  }
}
