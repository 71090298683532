@import '../../styles/common/layout';
@import '../../styles/common/vars';

.layout {
  @include row(flex-start, center);
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  .realTimeHandle {
    @include row;
    margin-left: 20px;

    .note {
      margin-left: 5px;
    }
  }

  :global(.bp5-button-group > .bp5-button) {
    background: var(--hubble-ui-bp-btn-bg);
    border: 1px solid var(--hubble-ui-bp-btn-border);
    border-radius: 6px;
    box-shadow: var(--hubble-ui-bp-btn-boxshadow);
    color: var(--hubble-ui-text-primary);
  }

  :global(.bp5-button-group > .bp5-button.bp5-active) {
    background: var(--hubble-ui-bp-btn-group-bg-active);
    border: 1px solid var(--hubble-ui-bp-btn-group-border-active);
    box-shadow: var(--hubble-ui-bp-btn-group-boxshadow-active);
    font-weight: 500;
  }
}

.hidden {
  opacity: 0;
}
