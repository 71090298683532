@import '../../styles/common/layout';

.panel {
  @include column;
  position: absolute;
  bottom: 0;
  top: 66%;
  width: 100%;
  flex-shrink: 0;
  z-index: var(--bottom-panel-z-index);

  background-color: var(--hubble-ui-panel-bg);
  box-shadow: var(--hubble-ui-panel-boxshadow);

  .dragPanel {
    @include exact-size(default, 10px);
    @include row(flex-start);

    flex-grow: 1;
  }

  .content {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }
}
