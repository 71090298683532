.wrapper {
  $margin-bottom: 10px;
  $header-height: 28px;
  $unsupported-warning-height: 26px;

  height: calc(100% - #{$margin-bottom});
  margin-bottom: $margin-bottom;
  margin-right: 7px;
  padding: 14px 0;
  overflow: hidden;
  background: var(--hubble-ui-yamlpanel-bg);
  border: 1px solid var(--hubble-ui-yamlpanel-border);
  border-radius: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 0 14px;

    .left,
    .center,
    .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .left {
      .leftActions {
        margin-right: 10px;

        > * {
          margin-right: 3px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .center {
      flex: 1;
      justify-content: flex-start;
      margin-left: 10px;
      margin-right: 10px;

      .specsSelector {
        margin-right: 10px;
      }
    }

    .right {
      justify-content: flex-end;

      .policyRating {
        display: flex;
        align-items: center;
        margin-right: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        cursor: help;

        .policyRatingTitle {
          margin-right: 7px;
          color: var(--hubble-ui-yamlpanel-policyrating-title);
        }
      }
    }
  }

  .unsupportedWarning {
    display: block;
    max-width: calc(100% - 28px);
    height: $unsupported-warning-height;
    margin: 14px 14px 0;
    padding: 0 10px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    line-height: $unsupported-warning-height;
    background-color: var(--hubble-ui-yamlpanel-unsupported-warning-bg);
    color: var(--hubble-ui-yamlpanel-unsupported-warning);
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    cursor: help;
  }

  .editor {
    margin-top: 14px;
    height: calc(100% - #{$header-height} + 3px);

    &.unsupportedWarningOpened {
      height: calc(100% - #{$header-height} + 3px - 14px - #{$unsupported-warning-height});
    }

    .newEmptyPolicyButton {
      &:global(.bp5-button) {
        border-radius: 5px;
      }
    }
  }

  .uploadYamlButton,
  .newYamlButton,
  .downloadYamlButton,
  .shareYamlButton {
    flex-shrink: 0;
    white-space: pre;
  }

  .newYamlButton,
  .uploadYamlButton {
    :global(.bp5-icon.bp5-icon-document) {
      color: var(--hubble-ui-bp-icon-doc) !important;
    }

    :global(.bp5-icon.bp5-icon-upload) {
      color: var(--hubble-ui-bp-icon-upload) !important;
    }
  }
}
