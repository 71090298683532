@import '../../styles/common/prelude';

.coloredCircle {
  @include column;

  &.red .circle {
    background: linear-gradient(
      to bottom,
      var(--hubble-ui-status-circle-red-light),
      var(--hubble-ui-status-circle-red-dark)
    );
  }

  &.green .circle {
    background: linear-gradient(
      to bottom,
      var(--hubble-ui-status-circle-green-light),
      var(--hubble-ui-status-circle-green-dark)
    );
  }

  .circle {
    flex-grow: 1;
    height: 100%;

    border-radius: 50%;
    overflow: hidden;
  }
}
