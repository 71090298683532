.orOperator {
  align-items: center;
  background-color: var(--hubble-ui-searchbar-operator-or-bg);
  border-radius: 4px;
  color: var(--hubble-ui-searchbar-operator-or-color);
  display: flex;
  height: 1.3rem;
  margin: 0 5px;
  padding: 4px 5px;
}
