@import '~/styles/common/layout';

@mixin setup-animation($name) {
  animation-name: $name;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@mixin circle-color($cls, $clr) {
  &#{$cls} {
    .circle {
      background-color: $clr;
    }
  }
}

.wrapper {
  @include row(flex-start, center);

  .circle {
    @include exact-size(10px, 10px);
    border-radius: 50%;
  }

  .label {
    margin-left: 7px;

    font-size: 14px;
    font-weight: 500;
    color: var(--hubble-ui-connectionindicator-label);

    span.dots {
      & > span {
        @include setup-animation(streaming-indicator-dot);
      }

      & > span:nth-child(2) {
        animation-delay: 0.15s;
      }

      & > span:nth-child(3) {
        animation-delay: 0.3s;
      }
    }

    .dotDivider {
      margin-left: 8px;
      margin-right: 8px;
    }

    .flowsLabel,
    .nodesLabel {
      color: var(--hubble-ui-connectionindicator-flows-nodes-label);
    }
  }

  @include circle-color('', var(--hubble-ui-connectionindicator-color-idle));
  @include circle-color('.idle', var(--hubble-ui-connectionindicator-color-idle));
  @include circle-color('.receiving', var(--hubble-ui-connectionindicator-color-receiving));
  @include circle-color('.timescape', var(--hubble-ui-connectionindicator-color-receiving));
  @include circle-color('.reconnecting', var(--hubble-ui-connectionindicator-color-reconnecting));
  @include circle-color('.stopped', var(--hubble-ui-connectionindicator-color-stopped));

  &.circleAnimated {
    .circle {
      @include setup-animation(streaming-indicator-circle);
    }
  }
}

@keyframes streaming-indicator-dot {
  0% {
    opacity: 0.15;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.15;
  }
}

@keyframes streaming-indicator-circle {
  0% {
    opacity: 0.75;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.75;
  }
}
