@import '../../styles/common/layout';

.app {
  display: flex;

  width: 100%;
  height: 100%;

  .sidebar {
    flex-shrink: 0;
    width: var(--sidebar-width);
  }
}

.workspace {
  @include column;
  flex-grow: 1;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  background: var(--hubble-ui-map-bg);
  box-shadow: var(--hubble-ui-svcmap-boxshadow);

  .searchInputWrapper {
    align-items: center;
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 10;
    box-shadow: var(--hubble-ui-svcmap-filter-input-shadow);
    transition: margin-top 0.25s ease-in-out;

    &.backdrop {
      margin: 0;
      width: 100%;
      background-color: var(--hubble-ui-searchbar-container-bg);
    }

    &.hidden {
      margin-top: -50px;
    }
  }
}

.dialogFooter {
  gap: 0 !important;

  :global(.bp5-dialog-footer-actions) {
    width: 100%;

    > label {
      flex-grow: 1;
    }
  }
}
