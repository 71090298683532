.foreignObject {
  .dimensionalWrapper {
    width: auto;
    height: auto;

    flex-grow: 1;

    // NOTE: do not set min-width/height here
    box-sizing: border-box;

    & > div {
      flex: 1 0 100%;

      width: auto;
      height: auto;

      box-sizing: border-box;
    }
  }
}
