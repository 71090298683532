@import '../../styles/common/layout';

.panel {
  @include column;
  position: absolute;
  bottom: 0;
  top: 66%;
  width: 100%;

  background-color: var(--hubble-ui-panel-bg);

  &.resizable {
    box-shadow: var(--hubble-ui-panel-boxshadow);
    transition: top 0.25s ease-out;
  }

  &.isDragging {
    transition: none;
  }

  .dragPanel {
    @include exact-size(default, 29px);
  }

  .tableWrapper {
    flex: 1;
    overflow: hidden;

    // NOTE: relative position is required for FlowsTableSidebar not to expand
    // NOTE: over the margins of FlowsTable
    position: relative;
  }

  .dashboardWrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
}
