.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 15px;
  background-color: var(--hubble-ui-background);
}

.grafanaWrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100% + 32px);
  height: 100%;
  margin-top: 10px;
  margin-left: -16px;
}

.grafanaDashboardSelector {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.grafanaInnerWrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.logo {
  display: block;
  flex-shrink: 0;
  width: 142px;
  height: 167px;
}

.description {
  display: block;
  flex-shrink: 0;
  margin: 25px 0 15px 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--hubble-ui-dashboard-namespaces-description);
}

.spinnerWrapper {
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-start;
}
