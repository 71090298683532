@import '../../../styles/common/layout';
@import '../../../styles/common/core';

.logsUpload {
  @include column;

  margin: 0 15px;

  .fileUpload {
    input {
      display: none;
    }

    .uploadButton {
      @include row;

      width: 100%;

      span {
        margin-right: 5px;
        font-weight: 500;
        color: var(--hubble-ui-navsidebar-ptree-upload-btn-text);
      }
    }
  }

  .uploadHelp {
    @include row(flex-start, center);

    margin-bottom: 10px;

    .handle {
      cursor: pointer;

      .icon {
        svg {
          transition: all 0.5s;
          fill: var(--hubble-ui-navsidebar-ptree-upload-help-icon) !important;
        }

        &:global(.bp5-icon-help) {
          margin-right: 5px;
        }
      }

      span {
        transition: all 0.5s;
        position: relative;
        top: 1px;

        font-size: 14px;
        color: var(--hubble-ui-navsidebar-ptree-upload-help-text);

        text-decoration: underline;
        text-decoration-style: solid;
        text-underline-offset: 4px;
      }

      &:hover {
        span {
          color: var(--hubble-ui-navsidebar-ptree-upload-help-text-hover);
        }

        .icon {
          svg {
            fill: var(--hubble-ui-navsidebar-ptree-upload-help-icon-hover) !important;
          }
        }
      }
    }
  }
}

.helpContent {
  font-size: 14px;

  .cmd {
    font-family: 'Roboto Mono', sans-serif;
    font-size: 12px;

    margin-top: 10px;
    padding: 15px;

    border: 1px solid var(--hubble-ui-navsidebar-ptree-upload-help-cmd-border);
    border-radius: 2px;
    color: var(--hubble-ui-text-primary);

    overflow-x: scroll;
    white-space: nowrap;
  }
}
