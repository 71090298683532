@import '../../../styles/common/layout';
@import '../../../styles/common/core';

$border-width: 1px;
$normal-height: 30px;

.button {
  @include fonts;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: var(--hubble-ui-ui-btn-bg);
  border: $border-width solid var(--hubble-ui-ui-btn-border);
  border-radius: 6px;
  box-shadow: var(--hubble-ui-ui-btn-boxshadow);

  min-height: $normal-height;
  padding: 0 15px;

  cursor: pointer;

  // transition: border-color 0.3s;

  &:not(.disabled):hover {
    background: var(--hubble-ui-ui-btn-bg-hover);
    box-shadow: none;
    transition: 3.84s;
  }

  &.disabled {
    cursor: default;
  }

  &.active {
    background: var(--hubble-ui-ui-btn-bg-active);
    border-color: var(--hubble-ui-ui-btn-border-active);
    border-width: $border-width;
    box-shadow: var(--hubble-ui-ui-btn-boxshadow-active);
  }

  &.noRightRounding {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.noLeftRounding {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.noRightRounding + &.noLeftRounding {
    &:not(.active) {
      border-left-width: 0;
    }
  }

  &.noRightBorder {
    border-right-width: 0;
  }
}
