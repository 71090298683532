// Thanks to Martin Wolf for blinking css dots
// https://martinwolf.org/before-2018/blog/2015/01/pure-css-savingloading-dots-animation/

@mixin setup-animation($name) {
  animation-name: $name;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes blinking-dot {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.loadingDots {
  .animatedDots {
    & > span {
      @include setup-animation(blinking-dot);
    }

    & > span:nth-child(2) {
      animation-delay: 0.2s;
    }

    & > span:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
