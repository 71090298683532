@import '../../styles/common/layout';
@import '../../styles/common/vars';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
  border-right: 1px solid var(--hubble-ui-border);

  background-color: var(--hubble-ui-background-secondary);

  .section {
    padding: 0 15px;
  }

  .navigationIcon {
    width: 31px;
    height: 31px;
  }

  .expandSection {
    flex: 1;
    overflow: auto;
  }

  .separator {
    background-color: var(--hubble-ui-border);
    height: 1px;
  }

  .title {
    margin-top: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
    color: var(--hubble-ui-text-secondary);
  }

  .groupTitle {
    margin-top: 10px;
    margin-bottom: 2px;
    color: var(--hubble-ui-text-secondary);
    font-size: 13px;
    font-weight: 700;
  }

  .errorCallout {
    margin-bottom: 10px;
    font-size: 13px;
    background-color: var(--hubble-ui-sidebar-error-callout-bg);
    color: var(--hubble-ui-sidebar-error-callout-text);
    padding: 5px;
    border-radius: 3px;
  }

  .clusterwideSwitcherWrapper {
    margin-left: 2px;
    margin-bottom: 7px;
    font-size: 14px;
  }

  .processTreeSection {
    & > .namespaceWrapper {
      margin-bottom: 15px;
    }

    .timeRangePicker {
      margin-top: 20px;
    }
  }

  .connectionsMapSection {
    .serviceMapLogsUploader {
      margin-top: 10px;
    }
  }

  .pickerButton {
    @include row;
    flex-grow: 1;
  }

  .filtersErrorBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    color: var(--hubble-ui-navsidebar-policies-filtersbanner-text);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
}

.statusCenterPopover {
  :global(.bp5-popover-content) {
    overflow: hidden;
    border-radius: 5px;
  }

  &:global(.bp5-popover) {
    border-radius: 5px;
    box-shadow: none;
    filter: drop-shadow(var(--hubble-ui-statuscenter-bp-popover-dropshadow));

    :global(.bp5-popover-arrow) {
      left: -10px !important;
    }

    :global(.bp5-popover-arrow::before) {
      box-shadow: none !important;
    }

    :global(.bp5-popover-arrow-border) {
      fill: none;
    }

    :global(.bp5-popover-arrow-fill) {
      fill: var(--hubble-ui-statuscenterwindow-bg) !important;
      filter: drop-shadow(var(--hubble-ui-statuscenter-bp-popover-dropshadow));
    }
  }
}

.sessionExpired {
  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
