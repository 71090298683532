@import '~/styles/common/layout';

$dot-size: 5px;
$icon-dimension-size: 36px;

.accessPoint {
  @include row(flex-start, center);

  .icons {
    @include row(flex-start, center);
    margin-right: 10px;
    position: relative;

    .connectorMock {
      @include exact-size($icon-dimension-size, $icon-dimension-size);
      position: absolute;
    }

    .connectorIcon {
      @include exact-size(default, $icon-dimension-size);
    }

    img {
      height: 100%;
    }
  }

  .data {
    @include row(flex-start, center);
    font-size: 24px;

    .dot {
      @include exact-size($dot-size, $dot-size);

      border-radius: 50%;
      background: var(--hubble-ui-svcmap-card-dot-bg);

      margin: 0 10px;
    }

    .port {
      font-size: 24px;
      color: var(--hubble-ui-svcmap-card-port);
    }

    .protocol {
      color: var(--hubble-ui-svcmap-card-protocol);
    }
  }
}
