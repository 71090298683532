@import '../../styles/common/layout';
@import '../../styles/common/core';

.table {
  @include column;

  .header {
    @include row;
    position: sticky;
    top: 0;
    z-index: 10;

    font-weight: 500;
    color: var(--hubble-ui-ptree-eventstable-header-text);

    background: var(--hubble-ui-ptree-eventstable-header-bg);
    border-bottom: 1px solid var(--hubble-ui-ptree-eventstable-row-border-bottom);

    &:hover {
      background-color: var(--hubble-ui-ptree-eventstable-row-bg-hover);
    }

    .cell {
      @include row;
      color: var(--hubble-ui-ptree-eventstable-header-text);
    }
  }

  .rows {
    z-index: 5;
  }
}
