.wrapper {
  width: 420px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  padding: 15px 19px;
  font-size: 14px;

  :global(.bp5-input-ghost),
  :global(.bp5-tag) {
    font-size: 14px;
  }

  .description {
    display: inline-block;
    margin-bottom: 12px;
    word-wrap: break-word;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-weight: 700;
      color: var(--hubble-ui-netpol-card-crudpopover-title);
      margin-bottom: 5px;
    }

    .text + .title {
      margin-top: 10px;
    }

    .text {
      color: var(--hubble-ui-text-primary);
    }
  }

  .section {
    position: relative;
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.l7 {
      margin: 0 10px;

      :global(.bp5-switch) {
        position: relative;
        top: -4px;
      }
    }

    .label {
      display: inline-block;
      position: relative;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: var(--hubble-ui-netpol-card-crudpopover-label);

      &:last-child {
        margin-bottom: 0;
      }

      &.required {
        &::after {
          position: absolute;
          top: 3px;
          right: -20px;
          content: '﹡';
          font-size: 16px;
          color: var(--hubble-ui-netpol-card-crudpopover-label-req);
        }
      }

      :global(.bp5-icon.bp5-icon-double-caret-vetical) {
        color: var(--hubble-ui-bp-icon-caret-vertical);
      }

      :global(.bp5-icon.bp5-icon-caret-up) {
        color: var(--hubble-ui-bp-icon-caret-up);
      }
    }

    .addExpressionButton {
      position: absolute;
      top: -3px;
      right: 0;
      font-weight: 500;
    }

    .labelNote {
      display: block;
      margin-top: 3px;
      color: var(--hubble-ui-netpol-card-crudpopover-label-note);
    }

    .namespaceSelectorAllCheckbox {
      margin-top: 5px;
      font-size: 90%;
    }
  }

  .warn {
    margin-left: -3px;
    margin-right: -3px;
    background-color: var(--hubble-ui-netpol-card-crudpopover-warning-bg);
    color: var(--hubble-ui-netpol-card-crudpopover-warning);
    padding: 3px 7px;
    border-radius: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    margin-top: 12px;
    margin-left: -3px;
    margin-right: -3px;
    background-color: var(--hubble-ui-netpol-card-crudpopover-error-bg);
    color: var(--hubble-ui-netpol-card-crudpopover-error);
    padding: 3px 7px;
    border-radius: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.podSelectorTextarea {
  display: block;
  width: 100%;
}
