.container {
  background-color: var(--hubble-ui-searchbar-form-bg);
  padding: 0 0 10px;
  position: relative;
}

.section {
  background-color: var(--hubble-ui-searchbar-form-bg);
}

.label {
  color: var(--hubble-ui-searchbar-form-label);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}

.selectNamespacesDropdownButton {
  align-items: center;
  background: var(--hubble-ui-searchbar-form-select-bg);
  border: 1px solid var(--hubble-ui-searchbar-form-select-border);
  border-radius: 6px;
  box-shadow: var(--hubble-ui-searchbar-form-select-box-shadow);
  display: flex;
  height: 2em;
  padding-left: 5px;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: var(--hubble-ui-searchbar-form-select-bg-hover);
  }
}

.selectOptionText {
  color: var(--hubble-ui-searchbar-form-select-text);
  font-size: 14px;
}

.menuList {
  position: absolute;
  width: 100%;
  max-height: 300px;
  background: var(--hubble-ui-searchbar-form-select-item-bg);
  border-radius: 5px;
  filter: var(--hubble-ui-searchbar-form-select-item-filter);
  padding-left: 0;
  overflow: auto;
  z-index: 2;
}

.listItem {
  color: var(--hubble-ui-searchbar-form-select-text);
  font-size: 14px;
  list-style: none;
  padding: 3px 6px;

  &:hover {
    color: var(--hubble-ui-searchbar-form-select-item-text-highlighted);
    cursor: pointer;
  }

  &.highlighted {
    background-color: var(--hubble-ui-searchbar-form-select-item-bg-highlighted);
    color: var(--hubble-ui-searchbar-form-select-text-highlighted);
  }

  &.selected {
    background-color: var(--hubble-ui-searchbar-form-select-item-bg-selected);
    color: var(--hubble-ui-searchbar-form-select-text-selected);
  }
}
