.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 14px 9px;
  margin-bottom: 8px;
  background: var(--hubble-ui-netpol-rec-card-bg);
  box-shadow: 0 0 34px var(--hubble-ui-netpol-rec-card-boxshadow);
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .ignoreButton {
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 3px 10px 3px 3px;
    scale: 0.8;
    opacity: 0.8;
  }

  .kind {
    display: block;
    font-size: 11px;
    font-weight: 600;

    &.violation {
      color: var(--hubble-ui-netpol-rec-card-violation);
    }

    &.recommendation {
      color: var(--hubble-ui-netpol-rec-card-rec);
    }
  }

  .title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    margin: 4px 0;
    color: var(--hubble-ui-netpol-rec-card-title);
  }

  .description {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: var(--hubble-ui-netpol-rec-card-descr);
    margin-bottom: 8px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .action {
      font-size: 12px;
      margin-left: 5px;
      margin-bottom: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
