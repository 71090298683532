:global(.bp5-overlay) {
  display: flex;
  justify-content: center;

  :global(.bp5-overlay-start-focus-trap) {
    outline: none;
  }
}

:global(.bp5-overlay-backdrop) {
  background-color: rgba(47, 53, 61, 0.2) !important;
}

.wrapper {
  background: var(--hubble-ui-searchbar-form-bg);
  border-radius: 11px;
  box-shadow: var(--hubble-ui-searchbar-form-dropshadow);
  margin: 0 10px 0 10px;
  top: 100%;
  z-index: 1000;
  margin-top: calc(var(--filter-group-top-bar-height) - 5px);
  flex-grow: 1;
  max-height: 80vh;
  max-width: 800px;
  overflow: auto;
  display: relative;
}

.inner {
  padding: 16px 8px;
}

.footer {
  position: sticky;
  bottom: 0;
  left: -8px;
  width: 100%;
  padding: 8px;
  background: var(--hubble-ui-searchbar-form-bg);
  box-shadow: var(--hubble-ui-searchbar-form-dropshadow);
}

.addFilterGroupButton {
  background-color: var(--hubble-ui-searchbar-form-add-filter-group-btn-bg);
  border: 1px solid var(--hubble-ui-searchbar-form-add-filter-group-btn-border);
  border-radius: 5px;
  font-weight: 600;
  padding: 8px;

  &:hover {
    cursor: pointer;
  }
}
