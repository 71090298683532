.wrapper {
  display: block;
  padding: 10px 20px;
  background-color: var(--hubble-ui-nodeconnectivity-bg);
  width: 100%;
  height: 100%;
  overflow: auto;
}

.inputsArea {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  :global(.bp5-form-group) {
    flex: 1;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}
