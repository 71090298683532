@import '../../styles/common/layout';
@import '../../styles/common/vars';

.wrapper {
  @include row(flex-start, center);
  flex-grow: 1;

  .toggle {
    text-decoration: underline;
    cursor: pointer;

    color: $blue;
    margin-left: 10px;
  }

  .handle {
    .startInput {
      position: relative;
      margin-right: 30px;

      &::after {
        display: flex;
        position: absolute;
        right: -24px;
        top: 2px;

        content: url('/icons/misc/arrow-date-range.svg');
      }
    }

    .startInput,
    .endInput {
      input {
        border-radius: 6px;
        border: 1px solid var(--hubble-ui-bp-timepicker-border);

        width: 132px;
        height: 23px;
        font-size: 12px;

        box-shadow: var(--hubble-ui-bp-timepicker-boxshadow);
      }
    }
  }

  .loading {
    @include exact-size(20px, 20px);
    margin-left: 10px;
  }
}

.popover {
  width: 100%;
}

.verticalButton {
  @include row(flex-start);
  flex-grow: 1;

  :global(.bp5-button-text) {
    @include row;
    display: inline-flex;

    flex-grow: 1;
  }

  .buttonContent {
    @include row(flex-start, center);
    flex-grow: 1;

    .arrowDown {
      position: relative;
      left: 1px;
      font-size: 22px;
      color: var(--hubble-ui-timepicker-icon-arrow);
    }

    .timeRangeRows {
      flex-grow: 1;
      margin-left: 7px;

      .timeRangeTop {
        padding-bottom: 5px;
        margin-bottom: 5px;

        border-bottom: 1px solid var(--hubble-ui-bp-timepicker-border);
      }
    }
  }
}
