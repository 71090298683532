.section {
  padding: 0 0 10px;
}

.label {
  color: var(--hubble-ui-searchbar-form-label);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}

.btnGroup {
  border: 1px solid var(--hubble-ui-searchbar-form-direction-btn-group-border);
  border-radius: 6px;
  display: inline-flex;

  .directionBtn {
    background: var(--hubble-ui-searchbar-form-direction-btn-bg);
    border: none;
    box-shadow: var(--hubble-ui-searchbar-form-direction-btn-boxshadow);
    cursor: pointer;
    padding: 5px 10px;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:not(:last-child) {
      border-right: 1px solid var(--hubble-ui-searchbar-form-direction-btn-border-right);
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &.selected {
      background: var(--hubble-ui-searchbar-form-direction-btn-bg-active);
      border-color: var(--hubble-ui-searchbar-form-direction-btn-border-active);
      box-shadow: var(--hubble-ui-searchbar-form-direction-btn-boxshadow-active);
    }
  }
}
