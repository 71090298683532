@use 'sass:color';
@import '../../styles/common/layout';
@import '~@blueprintjs/core/lib/scss/variables';

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .table {
    background-color: var(--hubble-ui-flowstable-bg);
    overflow-x: hidden !important;
  }

  .row {
    position: absolute;
    display: flex;
    width: 100%;
    font-size: 11px;
    font-weight: 500;
    line-height: var(--flows-table-row-height);
    color: var(--hubble-ui-flowstable-row);
    border-bottom: 1px solid var(--hubble-ui-flowstable-row-border);

    &.odd {
      background-color: var(--hubble-ui-flowstable-row-odd-bg);
    }

    &.even {
      background-color: var(--hubble-ui-flowstable-row-even-bg);
    }

    &:not(.head, .selected):hover {
      background-color: var(--hubble-ui-flowstable-row-bg-hover);
      cursor: pointer;
    }

    $cell-padding-right: 8px;

    &.head {
      position: sticky;
      top: 0;
      z-index: 2;
      line-height: var(--flows-table-head-height);
      background-color: var(--hubble-ui-flowstable-head-bg);
      border-top: 1px solid var(--hubble-ui-flowstable-head-border);
      border-bottom: 1px solid var(--hubble-ui-flowstable-head-border);
      color: var(--hubble-ui-flowstable-head);
      font-weight: 600;
      text-align: left;

      .cell {
        $handle-width: 10px;

        @mixin handle() {
          content: '\22EE';
          display: block;
          background-color: var(--hubble-ui-flowstable-head-bg);
          color: #b3babe;
          text-shadow: #fff 3px 1px 1px;
          width: $handle-width;
          border-right: 2px solid var(--hubble-ui-flowstable-head-border);
          height: 100%;
          font-size: 16px;
          line-height: 24px;
          position: absolute;
          pointer-events: none;
        }

        .label {
          width: 100%;
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;

          &::before {
            @include handle;
            margin-left: -$cell-padding-right - $handle-width;
          }
        }

        &:first-child .label::before {
          display: none;
        }

        &:last-child .label::after {
          @include handle;
          top: 0;
          right: 0;
        }
      }
    }

    &.selected {
      background-color: var(--hubble-ui-flowstable-row-bg-selected);

      .cell {
        color: var(--hubble-ui-flowstable-row-selected);
      }

      .subtitle {
        color: var(--hubble-ui-flowstable-subtitle-selected);
      }
    }

    .cell {
      padding-left: 8px;
      padding-right: $cell-padding-right;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;
      box-sizing: border-box;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      &.dstPort {
        max-width: 130px;
        text-align: right;
      }

      &.trafficDirection {
        max-width: 130px;
      }

      &.verdict {
        max-width: 130px;
      }

      &.tcpFlags {
        max-width: 130px;
      }

      &.timestamp {
        max-width: 220px;

        .cellLabel {
          font-family: 'Roboto Mono', monospace;
        }

        .year {
          color: var(--hubble-ui-flowstable-ymd);
        }
      }

      &.l7info {
        display: flex;

        &,
        .http {
          & > span:not(:last-child) {
            margin-right: 5px;
          }

          display: flex;

          .method {
            color: var(--hubble-ui-flowstable-method);
          }

          .latency {
            margin-left: auto;
          }
        }

        .direction {
          color: var(--hubble-ui-flowstable-direction);
        }
      }
    }
  }

  .emptyValue {
    color: var(--hubble-ui-flowstable-emptyval);
    font-weight: 400;
  }

  .subtitle {
    color: var(--hubble-ui-flowstable-subtitle);
    font-style: italic;
    font-weight: 500;
  }
}

.sidebar {
  $padding: 12px;

  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 320px;
  height: 100%;

  background: var(--hubble-ui-flowstable-sidebar-bg);
  border: 1px solid var(--hubble-ui-flowstable-sidebar-border);

  &.expanded {
    width: 90%;
    max-width: 1280px;
  }

  .main {
    @include column;
    flex-grow: 1;
    align-self: stretch;
    min-height: 100%;
    overflow: auto;

    .centeredOverlay {
      @include centered;
      flex-grow: 1;
      align-self: stretch;
    }
  }

  .aside {
    flex: 1;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding $padding 16px $padding;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: var(--hubble-ui-flowstable-sidebar-header);

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: $padding * 3;
      margin-top: -1px;
      padding: 3px;
      border-radius: 3px;
      color: var(--hubble-ui-flowstable-sidebar-icon-close);

      &:hover {
        cursor: pointer;
        color: var(--hubble-ui-flowstable-sidebar-icon-close-hover);
      }
    }
  }

  .action {
    padding-bottom: $padding;
    padding-left: $padding;
    padding-right: $padding;
    font-size: 14px;
  }

  .block {
    padding-left: $padding;
    padding-right: $padding;

    &:last-child {
      padding-bottom: $padding;
    }

    .title {
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: var(--hubble-ui-flowstable-sidebar-title);
    }

    .body {
      // @include ellipsis-limit();
      max-width: 100%;
      overflow-wrap: break-word;

      padding-bottom: 10px;
      font-size: 12px;
      line-height: 17px;
      color: var(--hubble-ui-flowstable-sidebar-text);

      .clickable {
        text-decoration: underline;
        text-decoration-color: var(--hubble-ui-flowstable-sidebar-link-decor);
        cursor: pointer;

        &:hover {
          color: var(--flowstable-sideba-link-hover);
          text-decoration-color: var(--hubble-ui-flowstable-sidebar-link-decor-hover);
        }

        &.selected {
          font-weight: 700;
        }

        &.forwardedVerdict {
          text-decoration-color: var(--hubble-ui-flowstable-sidebar-link-verdict-fwd-decor);

          &:hover {
            color: var(--hubble-ui-flowstable-sidebar-link-verdict-fwd-hover);
            text-decoration-color: var(--hubble-ui-flowstable-sidebar-link-verdict-fwd-decor-hover);
          }
        }

        &.droppedVerdict {
          text-decoration-color: var(--hubble-ui-flowstable-sidebar-link-verdict-drop-decor);

          &:hover {
            color: var(--hubble-ui-flowstable-sidebar-link-verdict-drop-hover);
            text-decoration-color: var(
              --hubble-ui-flowstable-sidebar-link-verdict-drop-decor-hover
            );
          }
        }
      }

      .labels {
        .label {
          display: block;
        }
      }

      .tcpFlags {
        .tcpFlag {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .policies {
        .policy {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .headerList {
        @include column;

        span {
          // @include ellipsis-limit();
          max-width: 100%;
        }
      }
    }
  }

  .blockSeparator {
    width: 100%;
    border-top: 1px solid var(--hubble-ui-flowstable-sidebar-hr);
    margin: 10px 0;

    & + & {
      display: none;
    }
  }
}

.forwardedVerdict {
  color: var(--hubble-ui-flowstable-verdict-fwd);
}

.droppedVerdict {
  color: var(--hubble-ui-flowstable-verdict-drop);
}

.authTypeFailed {
  color: var(--hubble-ui-text-red);
}

.rawInput {
  height: 100%;
  color: var(--hubble-ui-flowstable-sidebar-text);
  font-size: 12px;
}
