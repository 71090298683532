@import '~/styles/common/layout';

$icon-side-padding: 10px;
$wrapper-padding: 20px 20px;
$heading-max-width: calc(100% - var(--endpoint-icon-width) - #{$icon-side-padding} * 2);

.wrapper {
  @include column;

  width: 100%;
  max-width: 100%;

  padding: $wrapper-padding;
}

.headline {
  @include row(flex-start, center);

  cursor: pointer;
}

.logo {
  @include centered;
  flex-shrink: 0;

  $size: var(--endpoint-icon-width);
  @include exact-size($size, $size);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.headings {
  @include ellipsis-limit;

  flex: 1 1;
  max-width: $heading-max-width;
  margin-left: 15px;
}

.title,
.subtitle {
  @include ellipsis-limit;
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: var(--hubble-ui-svcmap-card-endpt-title);

  &.single {
    font-size: 32px;
  }
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  color: var(--hubble-ui-svcmap-card-endpt-subtitle);
}

.settingsIcon {
  @include column(flex-start);
  align-self: flex-start;

  img {
    width: 20px;
  }
}

.connectors {
  @include row(space-between, center);
  border-bottom: 1px solid var(--hubble-ui-svcmap-card-endpt-connectors-border);

  padding: 10px;
  margin: 5px 0 10px;

  .left,
  .right {
    @include row(default, center);
    font-size: 20px;
    height: 40px;

    img {
      width: 35px;
    }

    &.active {
      span {
        color: var(--hubble-ui-svcmap-card-endpt-connectors-active);
        font-weight: bold;
      }
    }
  }

  .left span {
    margin-left: 10px;
  }

  .right span {
    margin-right: 10px;
  }
}

.additionalInfoNetwork {
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}
