.wrapper {
  width: 100%;

  :global(.bp5-button-text) {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.popover {
  width: 100%;
}

:global(.bp5-select-popover .bp5-menu) {
  padding-left: 4px !important;
  padding-right: 9px !important;
}

:global(.bp5-menu-item-icon) {
  margin-left: -4px;
}
