@import '../../styles/common/layout';

.app {
  display: flex;

  width: 100%;
  height: 100%;

  .sidebar {
    position: relative;
    flex-shrink: 0;
    width: var(--sidebar-width);
  }

  .workspace {
    @include column;
    flex-grow: 1;

    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;

    .searchInputWrapper {
      align-items: center;
      border-radius: 11px;
      display: flex;
      position: absolute;
      width: 100%;
      z-index: 100;
    }

    .map {
      background: var(--hubble-ui-map-bg);
      flex-grow: 1;
    }
  }
}

.slackButton {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: var(--slack-button-z-index);
}

.alertsPortal {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: var(--alerts-portal-z-index);
}

.preload {
  position: absolute;
  top: -100000px;
  left: -100000px;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.onboarding,
.downloadDialog,
.shareDialog {
  .title {
    font-weight: bold;
    text-align: center;
    color: var(--hubble-ui-yamlpanel-dialog-title);
    margin-bottom: 20px;
  }

  h1.title {
    font-size: 22px;
    line-height: 22px;
  }

  h2.title {
    font-size: 18px;
    line-height: 18px;
  }

  .code {
    padding: 17px 14px;
    margin: 10px 0;
    background: var(--hubble-ui-yamlpanel-dialog-code-bg);
    border: 1px solid var(--hubble-ui-yamlpanel-dialog-code-border);
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;
    color: var(--hubble-ui-yamlpanel-dialog-code);
  }

  .tryItTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    margin: 22px 0 11px;
    text-align: left;
  }

  .requiresNote {
    font-size: 10px;
  }

  .locker {
    margin: 0 auto;
    display: block;
    width: 97.39px;
    height: 84.36px;
    margin-bottom: 22px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  svg {
    height: intrinsic;
  }
}

.wrapper {
  border-radius: 14px;
  user-select: none;
  background-color: var(--hubble-ui-svcmap-card-bg-primary);
  border: 2px solid var(--hubble-ui-svcmap-card-border);

  &:hover {
    &.clickable {
      cursor: pointer;
      box-shadow: 0 0 var(--endpoint-shadow-size)
        var(--hubble-ui-netpol-card-boxshadow-hover-clickable-color);
      transition: all ease-out 0.25s;
    }
  }

  &:active {
    cursor: grabbing;
  }
}

.content {
  background-color: var(--hubble-ui-netpol-card-bg-primary);
}
