.wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 150px;
  padding: 0 10px 5px;
  user-select: none;

  :global(.recharts-tooltip-wrapper):focus {
    z-index: 4;
    outline: none !important;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hubble-ui-flowschart-spinner-wrapper-bg);
}

.tooltip {
  width: 350px;
  max-height: 145px;
  background: var(--hubble-ui-flowschart-tooltip-bg);
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.yAxisLabel {
  position: absolute;
  top: 5px;
  left: 10px;
  color: var(--hubble-ui-flowschart-yaxis-label);
  font-size: 11px;
  z-index: 3;

  a {
    color: var(--hubble-ui-flowschart-yaxis-label-link);
    text-decoration: underline;

    &:hover {
      color: var(--hubble-ui-flowschart-yaxis-label-link-hover);
    }
  }
}

:global(.recharts-wrapper) {
  cursor: crosshair !important;
}

.tooltipHR {
  display: block;
  width: 100%;
  height: 1px;
  border: none;
  background: #ccc;
}

.k8seventItem {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  border-top: 1px solid #ccc;
  padding-top: 7px;
}
