.button {
  .k8s {
    padding-left: 21px;
    background: url('/icons/misc/k8s-logo.svg') no-repeat;
    background-position: left;
    background-size: 16.55px 16px;
  }

  .cilium {
    padding-left: 21px;
    background: url('/icons/misc/cilium-logo.svg') no-repeat;
    background-position: left;
    background-size: 26%;
    padding-bottom: 1px;
  }
}
