@import '../../styles/common/layout';

$header-padding: 13px 15px;

.cardHeader {
  @include column;

  width: 100%;
  max-width: 100%;

  padding: $header-padding;

  .top {
    @include row;

    :global(.bp5-icon-plus) {
      color: var(--hubble-ui-bp-icon-plus);
    }

    .addIcon {
      &:global(.bp5-button.bp5-minimal) {
        background: none;
        box-shadow: none;
        border: none;

        &:hover {
          background: var(--hubble-ui-bp-btn-bg-hover-2);
        }

        :global(.bp5-icon-plus) {
          color: var(--hubble-ui-bp-icon-plus);
        }

        :global(.bp5-icon-annotation) {
          color: var(--hubble-ui-bp-icon-annotation) !important;
        }
      }
    }
  }

  .icon {
    @include centered;
    flex-shrink: 0;
    width: 30px;
    margin-right: 9px;

    img {
      text-align: center;
    }
  }

  .captions {
    @include column(center);
    flex-grow: 1;

    .title {
      @include ellipsis-limit;

      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: var(--hubble-ui-netpol-card-header-title);
    }

    .subTitle {
      @include ellipsis-limit;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: var(--hubble-ui-netpol-card-header-subtitle);
    }
  }

  &.active {
    .icon {
      img {
        filter: brightness(2);
      }
    }

    .title,
    .subTitle {
      color: var(--hubble-ui-netpol-card-header-title-subtitle-active);
    }
  }
}

.innerEndpoints {
  padding: 0 10px;

  :global(.bp5-popover-wrapper) {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.endpointSelector {
  display: flex;
  flex-direction: column;
  margin: -5px 14px 14px;
  padding: 8px;
  font-weight: 600;
  background-color: var(--hubble-ui-netpol-card-endpt-selector-bg);
  border: 1px solid var(--hubble-ui-netpol-card-endpt-selector-border);
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  color: var(--hubble-ui-netpol-card-endpt-selector);

  .endpointSelectorLine {
    margin-bottom: 3px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.endpointItem {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  padding: 0 7px;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  white-space: pre;
  background-color: var(--hubble-ui-netpol-card-endpt-item-bg);

  .endpointItemContent {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--hubble-ui-netpol-card-endpt-item);
  }

  &:last-child {
    margin-bottom: 10px;
  }

  &:not(.notsupported) {
    cursor: pointer;

    &:hover {
      background-color: var(--hubble-ui-netpol-card-endpt-item-supported-bg-hover);
    }
  }

  border: 1px solid var(--hubble-ui-netpol-card-endpt-item-border);
  border-radius: 3px;

  &.active {
    background-color: var(--hubble-ui-netpol-card-endpt-item-bg-active);
    border-color: var(--hubble-ui-netpol-card-endpt-item-border-active);

    // color: var(--hubble-ui-netpol-card-endpt-item-active);

    &:hover {
      background-color: var(--hubble-ui-netpol-card-endpt-item-bg-active-hover);

      // color: var(--hubble-ui-netpol-card-endpt-item-active-hover);
    }
  }

  .port,
  .l7label,
  .cidrExceptIcon {
    position: static;
    flex-shrink: 0;
    margin-left: 4px;
  }

  .cidrExceptIcon {
    color: var(--hubble-ui-netpol-card-endpt-item-icon-cidr-excl);
  }
}

.wrapper {
  border: 1px solid transparent;

  &.active {
    background-color: var(--hubble-ui-netpol-card-bg-active);
    border-color: var(--hubble-ui-netpol-card-border-active);
    border-radius: 10px;

    &:hover {
      cursor: default;
    }

    .innerEndpoint {
      &.active {
        background-color: var(--hubble-ui-netpol-card-inner-endpt-bg-active);
        border-color: var(--hubble-ui-netpol-card-inner-endpt-border-active);
        color: var(--hubble-ui-netpol-card-inner-endpt-active);
        cursor: default;

        &:hover {
          cursor: default;
        }
      }

      &:hover {
        background-color: var(--hubble-ui-netpol-card-inner-endpt-bg-hover);
        border-color: var(--hubble-ui-netpol-card-inner-endpt-border-hover);
        color: var(--hubble-ui-netpol-card-inner-endpt-hover);
        cursor: pointer;
      }
    }
  }
}

.connectors {
  @include row(space-between, center);

  margin-top: 10px;

  .left,
  .right {
    @include row(default, center);
    font-size: 8px;
    line-height: 120%;
    height: 24px;

    color: var(--hubble-ui-netpol-card-connectors-btns) !important;
    font-weight: bold;

    &:global(.bp5-button.bp5-minimal) {
      background: none;
      border: none;
      box-shadow: none;

      &:hover {
        color: var(--hubble-ui-netpol-card-connectors-btns-hover);
        background: var(--hubble-ui-bp-btn-bg-hover-2);
      }
    }

    &.allow {
      color: var(--hubble-ui-netpol-card-connectors-btns-allow) !important;

      &:hover {
        color: var(--hubble-ui-netpol-card-connectors-btns-allow-hover);
      }
    }

    svg {
      fill: currentcolor;
    }
  }

  .left {
    margin-left: -10px;
  }

  .right {
    margin-right: -10px;
  }
}

.trimWarning {
  margin-bottom: 10px;
}

:global(.bp5-popover) {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15) !important;

  :global(.bp5-popover-content) {
    border-radius: 3px !important;
  }

  :global(.bp5-popover-arrow-fill) {
    fill: var(--hubble-ui-polcard-bp-bg) !important;
  }

  :global(.bp5-popover-arrow-border) {
    fill: var(--hubble-ui-polcard-bp-bg) !important;
  }
}
