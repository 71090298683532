.wrapper {
  background: var(--hubble-ui-searchbar-container-bg);
  min-height: var(--filter-group-top-bar-height);
  width: 100%;
}

.filtersBarContainer {
  // background: var(--hubble-ui-searchbar-container-bg);
  padding: 5px;
}

.content {
  align-items: center;
  background: var(--hubble-ui-searchbar-bg);
  border: 1px solid var(--hubble-ui-searchbar-border);
  border-radius: 6px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 4px;
}

.filterTagsContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  line-height: 1.5rem;
}

.clearFiltersButton {
  background: transparent;
  border: none;
  color: var(--hubble-ui-text-secondary);
  margin-left: 0.5rem;
  white-space: pre;

  &:hover {
    color: var(--hubble-ui-searchbar-clear-filter-btn-hover);
    cursor: pointer;
  }
}
