.wrapper,
.iframe {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex: 1;
  z-index: 10;
  background-color: var(--hubble-ui-background);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  // background: rgba(255, 255, 255, 1);
  // background: rgba(255, 255, 255, 0.5);
  background-color: var(--hubble-ui-background);
  z-index: 20;

  // pointer-events: none;
}
