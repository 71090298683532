.direction {
  align-items: center;
  background-color: var(--hubble-ui-searchbar-filter-tag-direction-bg);
  border: 1px solid var(--hubble-ui-searchbar-filter-tag-direction-border);
  border-radius: 4px;
  display: inline-flex;
  margin-right: 5px;
  padding: 1px 6px;
}

.label {
  font-style: italic;
}

.either {
  font-style: italic;
}

.from {
  color: var(--hubble-ui-searchbar-filter-tag-direction-from);
  padding-right: 4px;
}

.to {
  color: var(--hubble-ui-searchbar-filter-tag-direction-to);
  padding-left: 4px;
}

.icon {
  color: var(--hubble-ui-searchbar-filter-tag-icon);
  position: relative;
  top: -2.5px;
}
