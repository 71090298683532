.section {
  display: flex;
  align-items: center;
  padding: 0 0 10px;
}

.label {
  color: var(--hubble-ui-searchbar-form-label);
  font-size: 13px;
  font-weight: 600;
}

input[type='checkbox'] {
  appearance: none;
  background: var(--hubble-ui-searchbar-form-checkbox-bg);
  border: 1px solid var(--hubble-ui-searchbar-form-checkbox-border);
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  margin-left: 9px;
}

input[type='checkbox']::before {
  content: '';
  background-color: var(--hubble-ui-searchbar-form-checkbox-checkmark);
  width: 1rem;
  height: 1rem;
  clip-path: path(
    'M12.7071 6.70708C12.8881 6.52611 13 6.27613 13 6C13 5.44772 12.5523 5 12 5C11.7198 5 11.4665 5.11526 11.2849 5.30095L7.00008 9.5858L4.70718 7.29291L4.70715 7.29294C4.52618 7.11195 4.27617 7 4 7C3.44772 7 3 7.44772 3 8C3 8.27949 3.11466 8.5322 3.29951 8.71366L6.28634 11.7005C6.4678 11.8853 6.72051 12 7 12C7.27616 12 7.52617 11.8881 7.70714 11.7071L7.70718 11.7071L12.7072 6.70712L12.7071 6.70708Z'
  );
  transform: scale(0);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

// TODO: Add custom hover state color
// input[type='checkbox']:hover {
// }
