$hovered-control: #01a4ff;

.flowsInfoIndicator {
  cursor: default;

  &:hover {
    .circles {
      .outer {
        stroke: $hovered-control;
      }

      .dot {
        fill: $hovered-control;
      }
    }
  }
}
