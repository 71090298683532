@import '../../../styles/common/layout';
@import '../../../styles/common/core';

.buttonGroup {
  display: inline-flex;

  .button {
    // border: 1px solid red !important;
  }
}
