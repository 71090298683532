.wrapper {
  padding: 10px 20px;
  overflow: auto;
  height: 100%;
}

.item {
  cursor: default;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
