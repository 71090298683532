@import '~@blueprintjs/core/lib/scss/variables';
@import '~@blueprintjs/core/src/blueprint';
@import '~@blueprintjs/datetime/src/blueprint-datetime';
@import '~@blueprintjs/select/src/blueprint-select';

/* TABLE OF CONTENTS 
 
  1. Button
    -  Intent: None 
    -  Intent: Primary
    -  Intent: Success
    -  Intent: Warning
    -  Intent: Danger
    -  Minimal
    -  Outlined 
  2. Control 
  3. Date Picker  
  4. Date Range Picker 
  5. Dialog
  6. Input
  7. Menu
  8. Popover

*/

// $pt-border-radius: 3px;
// $pt-button-height: 26px;
// $pt-input-height: 26px;

/* ==============
   Button
   ============== */

.bp5-button {
  background: var(--hubble-ui-bp-btn-bg);
  border: 1px solid var(--hubble-ui-bp-btn-border);
  box-shadow: var(--hubble-ui-bp-btn-boxshadow);
  border-radius: 6px;
  color: var(-bp-btn-text);

  &:hover {
    background: var(--hubble-ui-bp-btn-bg-hover);
  }

  /* Intent: None */

  &:not([class*='bp5-intent-']) {
    background: var(--hubble-ui-bp-btn-bg);
    border: 1px solid var(--hubble-ui-bp-btn-border);
    border-radius: 6px;
    box-shadow: var(--hubble-ui-bp-btn-boxshadow);

    &:hover {
      background: var(--hubble-ui-bp-btn-bg-hover);
      box-shadow: none;
      transition: 3.84s;
    }
  }

  /* Intent: Primary */

  &.bp5-intent-primary {
    background: var(--hubble-ui-bp-btn-intent-primary-bg);
    border: 1px solid var(--hubble-ui-bp-btn-intent-primary-border);
    box-shadow: none;

    &:hover {
      background: var(--hubble-ui-bp-btn-intent-primary-bg-hover);
      border: 1px solid var(--hubble-ui-bp-btn-intent-primary-border-hover);
      box-shadow: none;
    }

    &:active {
      background: var(--hubble-ui-bp-btn-intent-primary-bg-active);
      border: 1px solid var(--hubble-ui-bp-btn-intent-primary-border-active);
    }
  }

  /* Intent: Success */

  &.bp5-intent-success {
    background: var(--hubble-ui-bp-btn-intent-success-bg);
    border: 1px solid var(--hubble-ui-bp-btn-intent-success-border);
    border-radius: 6px;
    color: white;

    &:hover {
      background: var(--hubble-ui-bp-btn-intent-success-bg-hover);
      border: 1px solid var(--hubble-ui-bp-btn-intent-success-border-hover);
    }

    &:active {
      background: var(--hubble-ui-bp-btn-intent-success-bg-active);
      border: 1px solid var(--hubble-ui-bp-btn-intent-success-border-active);
      box-shadow: var(--hubble-ui-bp-btn-boxshadow-2);
    }
  }

  /* Intent: Warning */

  &.bp5-intent-warning {
    background: var(--hubble-ui-bp-btn-intent-warning-bg);
    border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
    border-radius: 6px;
    color: white;

    &:hover {
      background: var(--hubble-ui-bp-btn-intent-warning-bg-hover);
      border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border-hover);
    }

    &:active {
      background: var(--hubble-ui-bp-btn-intent-warning-bg-active);
      border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border-active);
      box-shadow: var(--hubble-ui-bp-btn-boxshadow-2);
    }

    &:disabled {
      background: var(--hubble-ui-bp-btn-intent-warning-bg-disabled);
      color: var(--hubble-ui-bp-btn-intent-warning-disabled);
    }
  }

  /* Intent: Danger */

  &.bp5-intent-danger {
    background: var(--hubble-ui-bp-btn-intent-danger-bg);
    border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border);
    border-radius: 6px;
    box-shadow: none;

    &:hover {
      background: var(--hubble-ui-bp-btn-intent-danger-bg-hover);
      border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border-hover);
    }

    &:active {
      background: var(--hubble-ui-bp-btn-intent-danger-bg-active);
      border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border-active);
      box-shadow: var(--hubble-ui-bp-btn-boxshadow-2);
    }
  }

  &.bp5-minimal {
    border-radius: 5px;

    &:hover {
      background: var(--hubble-ui-bp-btn-bg-hover-2);
    }
  }

  /* ---- Outlined ---- */

  &.bp5-outlined {
    /* Intent: Success */

    &.bp5-intent-success {
      background: var(--hubble-ui-bp-btn-intent-success-bg);
      border: 1px solid var(--hubble-ui-bp-btn-intent-success-border);
      color: white;

      &:hover {
        background: var(--hubble-ui-bp-btn-intent-success-bg-hover);
        border: 1px solid var(--hubble-ui-bp-btn-intent-success-border-hover);
        color: white !important;
      }
    }

    /* Intent: Warning */

    &.bp5-intent-warning {
      &:hover {
        background: var(--bp5-btn-intent-warning-bg-hover);
        border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
      }

      &.bp5-disabled {
        background: var(--hubble-ui-bp-btn-intent-warning-bg-disabled);
        border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
        color: white;
      }
    }

    /* Intent: Danger */

    &.bp5-intent-danger {
      color: white;

      &:hover {
        background: var(--hubble-ui-bp-btn-intent-danger-bg-hover);
        border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border);
        color: white;
      }

      &.bp5-disabled {
        background: var(--hubble-ui-bp-btn-intent-danger-bg-disabled);
        border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border);
        color: var(--hubble-ui-bp-btn-intent-warning-disabled);
      }
    }

    &.bp5-active {
      background: var(--hubble-ui-bp-btn-group-bg-active);
      border: 1px solid var(--hubble-ui-bp-btn-group-border-active);
      box-shadow: var(--hubble-ui-bp-btn-group-boxshadow-active);
      font-weight: 500;
    }
  }
}

/* ==============
   Control
   ============== */

.bp5-control {
  margin-bottom: 0;
}

/* ==============
   Input
   ============== */

.bp5-input {
  border: 1px solid #cad0d6;
  box-shadow: none;
}

/* ==============
   Menu
   ============== */

.bp5-menu {
  .bp5-menu-item {
    label.bp5-control.bp5-checkbox {
      margin: 2px 4px;
    }

    &.bp5-active {
      background: var(--hubble-ui-bp-menu-item-bg-active);
      color: var(--hubble-ui-bp-menu-item-text-active);
    }
  }
}

.bp5-select-popover {
  .bp5-menu {
    padding-bottom: 5px !important;
  }
}

/* ==============
   Popover
   ============== */

.bp5-tooltip .bp5-popover-content {
  background: var(--hubble-ui-background);
  color: var(--hubble-ui-text-primary);
}

.bp5-popover {
  .bp5-popover-arrow-fill {
    fill: var(--hubble-ui-bp-bg-primary);
  }
}

/* ------------- Dark Theme ------------- */

/* ==============
   Button
   ============== */

.bp5-dark {
  .bp5-button {
    background: var(--hubble-ui-bp-btn-bg);
    border: 1px solid var(--hubble-ui-bp-btn-border);
    box-shadow: var(--hubble-ui-bp-btn-boxshadow);
    color: var(--hubble-ui-bp-btn-text);

    &:hover {
      background: var(--hubble-ui-bp-btn-bg-hover);
    }

    /* Intent: None */

    &:not([class*='bp5-intent-']) {
      background: var(--hubble-ui-bp-btn-bg);
      border: 1px solid var(--hubble-ui-bp-btn-border);
      border-radius: 6px;
      box-shadow: var(--hubble-ui-bp-btn-boxshadow);

      &:hover {
        background: var(--hubble-ui-bp-btn-bg-hover);
        box-shadow: none;
        transition: 3.84s;
      }
    }

    /* Intent: Primary */

    &.bp5-intent-primary {
      background: var(--hubble-ui-bp-btn-intent-primary-bg);
      border: 1px solid var(--hubble-ui-bp-btn-intent-primary-border);
      box-shadow: none;

      &:hover {
        background: var(--hubble-ui-bp-btn-intent-primary-bg-hover);
        border: 1px solid var(--hubble-ui-bp-btn-intent-primary-border-hover);
        box-shadow: none;
      }
    }

    /* Intent: Success */

    &.bp5-intent-success {
      background: var(--hubble-ui-bp-btn-intent-success-bg);
      border: 1px solid var(--hubble-ui-bp-btn-intent-success-border);
      border-radius: 6px;
      box-shadow: none;

      &:hover {
        background: var(--hubble-ui-bp-btn-intent-success-bg-hover);
        box-shadow: none;
      }
    }

    /* Intent: Warning */

    &.bp5-intent-warning {
      background: var(--hubble-ui-bp-btn-intent-warning-bg);
      border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
      border-radius: 6px;

      &:hover {
        border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
        background: var(--bp5-btn-intent-warning-bg-hover);
      }

      &.bp5-disabled {
        border: 1px solid var(--hubble-ui-bp-btn-intent-warning-border);
      }
    }

    /* Intent: Danger */

    &.bp5-intent-danger {
      background: var(--hubble-ui-bp-btn-intent-danger-bg);
      border: 1px solid var(--hubble-ui-bp-btn-intent-danger-border);
      border-radius: 6px;
      box-shadow: none;

      &:hover {
        background: var(--hubble-ui-bp-btn-intent-danger-bg-hover);
      }
    }

    /* ---- Minimal ---- */

    &.bp5-minimal {
      border-radius: 5px;
    }

    /* ---- Outlined ---- */

    &.bp5-outlined {
      background: var(--hubble-ui-bp-btn-group-bg);
      border: 1px solid var(--hubble-ui-bp-btn-border);
      border-radius: 5px;
      box-shadow: var(--hubble-ui-bp-btn-boxshadow);

      &:active {
        background: var(--hubble-ui-bp-btn-bg-active);
      }

      /* Intent: Success */

      &.bp5-intent-success {
        background: var(--hubble-ui-bp-btn-intent-success-bg);
        border: 1px solid var(--hubble-ui-bp-btn-intent-success-border);
        color: white;

        &:hover {
          color: white;
        }
      }

      /* Intent: Warning */

      &.bp5-intent-warning {
        &.bp5-disabled {
          background: var(--hubble-ui-bp-btn-intent-warning-bg-disabled);
          color: var(--hubble-ui-bp-btn-intent-warning-disabled);
        }
      }

      &.bp5-active {
        background: var(--hubble-ui-bp-btn-group-bg-active);
        border: 1px solid var(--hubble-ui-bp-btn-group-border-active);
        box-shadow: var(--hubble-ui-bp-btn-group-boxshadow-active);

        font-weight: 500;
      }
    }
  }

  /* ==================
     Date Picker
     ================== */

  .bp5-datepicker {
    background: var(--hubble-ui-bp-bg-primary);
    color: white;

    .DayPicker-Day {
      &.DayPicker-Day--selected {
        background-color: var(--hubble-ui-bp-daypicker-day-bg-selected);

        &:hover {
          background-color: var(--hubble-ui-bp-daypicker-day-bg-hover);
        }
      }
    }
  }

  /* ===================
     Date Range Picker
     =================== */

  .bp5-daterangepicker {
    .DayPicker-NavButton {
      &:hover {
        background: var(--hubble-ui-bp-btn-bg-hover-2);
      }
    }

    .DayPicker-Day {
      &--hovered-range {
        &:not(
            .DayPicker-Day--selected,
            .DayPicker-Day--selected-range,
            .DayPicker-Day--selected-range-start,
            .DayPicker-Day--selected-range-end
          ) {
          background-color: var(--hubble-ui-bp-daypicker-day-bg) !important;
        }
      }

      &--selected-range {
        background-color: var(--hubble-ui-bp-daypicker-selected-range-bg);

        &:hover {
          background-color: var(--hubble-ui-bp-daypicker-selected-range-bg-hover);
        }
      }
    }
  }

  /* ==============
     Dialog
     ============== */

  .bp5-dialog {
    background-color: var(--hubble-ui-bp-bg-primary);

    &.bp5-alert {
      background: var(--hubble-ui-bp-bg-primary);
      box-shadow: var(--hubble-ui-bp-dialog-boxshadow) !important;
    }
  }

  /* ==============
     Input
     ============== */

  .bp5-input {
    box-shadow: none;
    border: 1px solid var(--searchbar-backdrop-border);
  }

  /* ==============
     Menu
     ============== */

  .bp5-menu {
    background: var(--hubble-ui-bp-bg-primary);
    box-shadow: none;

    .bp5-menu-item {
      &.bp5-disabled {
        color: #a1b1bb !important;
      }

      &.bp5-selected {
        &,
        &:hover,
        &:active {
          background-color: var(--hubble-ui-bp-btn-bg-hover);
          color: white;
        }
      }
    }
  }

  /* ==============
     Popover
     ============== */

  &.bp5-popover {
    box-shadow: var(--hubble-ui-bp-popover-boxshadow) !important;

    .bp5-popover-content {
      background: var(--hubble-ui-bp-bg-primary);
    }

    .bp5-popover-arrow-border {
      fill: var(--hubble-ui-bp-bg-primary);
      fill-opacity: 1;
    }

    .bp5-popover-arrow-fill {
      fill: var(--hubble-ui-bp-bg-primary);
    }
  }

  .bp5-tooltip .bp5-popover-content {
    background: var(--hubble-ui-background);
    color: var(--hubble-ui-text-primary);
  }
}
