@use 'sass:color';

@import '../../styles/common/layout';
@import '../../styles/common/core';

.app {
  display: flex;

  width: 100%;
  height: 100%;

  .sidebar {
    position: relative;
    flex-shrink: 0;
    width: var(--sidebar-width);

    .longLoadingHelper {
      color: var(--hubble-ui-color-intent-warning);
      font-size: 12px;

      margin: 10px 0;

      .actions {
        margin-top: 5px;

        .cancelBtn {
          cursor: pointer;
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }
  }

  .workspace {
    @include column;
    flex-grow: 1;

    position: relative;
    overflow: hidden;

    width: 100%;

    background-color: var(--hubble-ui-background);

    .processTree {
      @include column;
      @include overflow(scroll);

      flex-grow: 1;
      flex-shrink: 1;
      padding: 10px 20px 0;

      .container {
        opacity: 0;
      }

      .caption {
        margin: 10px 0;
        font-weight: 800;
      }

      .svgTree {
        flex-grow: 1;
        width: 100%;
      }

      .empty {
        color: var(--hubble-ui-text-tertiary);
        font-size: 14px;
      }

      .podEventsStateIcon {
        @include centered;
        @include exact-size(50px, 50px);
      }
    }

    .eventsTable {
      @include column(flex-start, stretch);
      @include overflow(scroll);
      @include no-scrollbar;

      position: relative;
      height: 250px;
      max-width: calc(100vw - var(--sidebar-width));

      border-top: 1px solid var(--hubble-ui-ptree-eventstable-border);

      flex-grow: 0;
      flex-shrink: 0;

      .empty {
        @include centered;
        align-self: center;
        height: 100%;
        color: var(--hubble-ui-ptree-eventstable-empty-text);
        font-size: 16px;
      }

      .eventRow {
        @include row(flex-start, center);
        min-height: 20px;

        color: var(--hubble-ui-ptree-eventstable-row-text);
        white-space: nowrap;
        font-size: 14px;
      }

      .tableHolder {
        @include column;
        @include overflow(scroll);
        @include no-scrollbar;

        // @include exact-size(default, 100%);
        flex-grow: 1;

        position: relative;

        .table {
          max-height: 100%;

          // height: 100%;
        }

        .tabs {
          @include row(flex-start, center);
          margin: 0 10px 10px;

          // NOTE: blueprint tooltip generates wrong span
          & > span {
            display: inherit;
          }

          .flowsTooltip {
            font-size: 14px;
          }

          .tabButtons {
            .closeFlows {
              margin-left: 5px;

              &:hover svg {
                transition: fill 0.25s;
                fill: color.adjust(#8aa2b4, $lightness: -10%);
              }
            }

            .flows {
              padding-right: 10px;
            }
          }

          .help {
            margin-left: 10px;
          }
        }
      }

      .header {
        // NOTE: to shift it below resize handle
        top: 20px;
        background-color: var(--hubble-ui-ptree-eventstable-header-bg);
      }

      .resizeHandle {
        // NOTE: too high values can make resize handle overlap TimeRange popover
        z-index: 10;
      }
    }
  }
}
