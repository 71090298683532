@import '~/styles/common/layout';

$border-radius: 4px;
$border-width: 2px;
$padding-left: 10px;
$connector-dim-size: 25px;

.httpEndpoint {
  @include column;

  border-radius: $border-radius;
  font-size: 20px;

  & + & {
    margin-top: 10px;
  }

  .header {
    position: relative;

    padding: 5px $padding-left;
    overflow-wrap: break-word;

    font-weight: 600;
    font-family: Inter, 'Roboto Mono', monospace;
  }

  .connectors {
    @include column;
    padding: 5px $padding-left;

    .connector {
      @include row(flex-start, center);
      position: relative;

      .mockConnector {
        @include exact-size($connector-dim-size, $connector-dim-size);
        position: absolute;
      }

      .connectorIcon {
        @include exact-size(default, $connector-dim-size);
      }

      &.success {
        .method {
          color: var(--hubble-ui-svcmap-traffic-forwarded);
        }
      }

      &.fail {
        .method {
          color: var(--hubble-ui-svcmap-traffic-dropped);
          font-weight: 900;
        }
      }

      img {
        height: 100%;
      }

      .method {
        margin-left: 10px;
        color: var(--hubble-ui-svcmap-card-httpendpt-connector-circle-outer);

        text-transform: uppercase;
        font-weight: 600;
      }
    }

    .connector:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
