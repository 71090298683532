@use 'sass:color';
@import '~/styles/common/layout';
@import '~/styles/common/vars';

$page-width: 800px;
$warn-color: #ffc300;
$info-color: steelblue;

.ssrErrorPage {
  width: 100%;

  pre {
    margin: 0;
  }

  .content {
    max-width: $page-width;
    margin: auto;

    font-size: 14px;

    .caption {
      font-size: 32px;
      font-weight: 600;

      padding-top: 100px;

      margin-bottom: 25px;
    }

    .subcaption {
      margin-bottom: 10px;
    }

    .errorFrame,
    .infoFrame {
      border: 1px solid;
      border-left: 2px solid;
      border-radius: 4px;

      font-size: 12px;

      padding: 10px;
      margin-bottom: 20px;
      background: #f9f9f9;
    }

    .errorFrame {
      border-color: color.adjust($warn-color, $lightness: 30%);
      border-left-color: $warn-color;
    }

    .infoFrame {
      border-color: $info-color;
    }
  }
}
