.section {
  padding: 0 0 10px;
}

.label {
  color: var(--hubble-ui-searchbar-form-label);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}

.filterTextInput {
  background: var(--hubble-ui-searchbar-form-input-bg);
  border: 1px solid var(--hubble-ui-searchbar-form-input-border);
  border-radius: 6px;
  box-shadow: var(--hubble-ui-searchbar-form-input-boxshadow);
  color: var(--hubble-ui-searchbar-form-input-text);
  height: 2em;
  padding-left: 5px;
  width: 100%;
}
