.btn {
  align-items: center;
  background-color: var(--hubble-ui-searchbar-filter-bg);
  border-color: transparent;
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  min-height: 1rem;
  padding: 2px 8px;
}

.btn:hover {
  background-color: var(--hubble-ui-searchbar-add-filter-btn-bg-hover);
  cursor: pointer;
}
