@import '../../styles/common/layout';

.dragPanel {
  @include row(center, center);

  transition: all 0.25s ease-out;
  height: 100%;
  user-select: none;

  &.resizable:hover {
    cursor: row-resize;
    background-color: var(--hubble-ui-svcmap-dragpanel-bg-hover);
  }

  &.resizable:active {
    background-color: var(--hubble-ui-svcmap-dragpanel-bg-active);
    cursor: grabbing;
  }
}

.left,
.right {
  flex: 1;
  position: relative;
}

.left {
  @include row(flex-start, center);
}

.center {
  @include row(center, center);

  padding-left: 40px;
  padding-right: 40px;
}

.right {
  @include row(flex-end, center);
}
