.wrapper {
  padding: 15px 19px;
  width: 320px;
  overflow: hidden;

  .label {
    display: block;
    margin-bottom: 1px;
    font-size: 12px;
    line-height: 18px;
    color: var(--hubble-ui-policiespanel-settings-label);
  }
}

.ignoreLabelsInput {
  font-size: 16px;
}
