@use 'sass:math';

@import '../../../styles/common/core';
@import '../../../styles/common/layout';

$tooltip-color: #343e44;
$tooltip-arrow-diag: 25px;

.content {
  color: white;
}

.flowsInfoTooltip {
  @include column;

  .tooltipBody {
    position: relative;
    background: $tooltip-color;

    border-radius: 4px;
    padding: 10px;

    color: white;

    margin-bottom: $tooltip-arrow-diag * 0.5 - 2px;

    .tooltipContent {
      z-index: 10;
    }

    .tooltipArrow {
      background: $tooltip-color;
      border-radius: 2px;

      width: math.div($tooltip-arrow-diag, 1.41);
      height: math.div($tooltip-arrow-diag, 1.41);

      position: absolute;
      bottom: -$tooltip-arrow-diag * 0.25 + 2px;
      left: calc(50% - #{math.div($tooltip-arrow-diag * 0.5, 1.41)});
      z-index: -1;

      align-self: center;

      transform: rotate(45deg);
    }
  }
}
