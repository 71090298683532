.titleWrapper {
  :global(.bp5-button-text) {
    display: flex;
    max-width: 100%;
    overflow: hidden;
  }
}

.title {
  display: flex;
  max-width: 100%;

  .fluid {
    flex: 1 1 100%;
    min-width: 0;

    .fluidContent {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.dropped {
  color: var(--hubble-ui-verdict-dropped);
}

.forwarded {
  color: var(--hubble-ui-verdict-forwarded);
}
