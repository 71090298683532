@import './vars';

@mixin light-theme {
  color-scheme: light;

  /* System | Colors */
  --hubble-ui-color-white: #fff;
  --hubble-ui-color-black: #000;
  --hubble-ui-color-green: #288f0e;
  --hubble-ui-color-blue: #215db0;

  // --hubble-ui-color-green: #54a82d;
  --hubble-ui-color-red: #d2443b;

  /* Colors | Service Map */
  --hubble-ui-color-purple: #af5fff;

  /* Colors | Process Tree */
  --hubble-ui-color-intent-warning: #946a00;

  /* System | Backgrounds */
  --hubble-ui-background: #fff;
  --hubble-ui-background-secondary: #f8f9fa;
  --hubble-ui-background-secondary-selected: #e5ecf1;
  --hubble-ui-background-secondary-hover: #f3f6f6;
  --hubble-ui-background-tertiary-1: #e5ebef;
  --hubble-ui-background-tertiary-2: #ccd7df;

  /* System | Borders */
  --hubble-ui-border: #cfd3d7;
  --hubble-ui-border-selected: #ccd7df;

  /* System | Typography */
  --hubble-ui-text-primary: #000;
  --hubble-ui-text-secondary: #5c7080;
  --hubble-ui-text-tertiary: #78868e;
  --hubble-ui-text-green: #288f0e;
  --hubble-ui-text-red: #d2443b;

  /* Nav Sidebar | Flows Verdicts | Flows Table */
  --hubble-ui-verdict-dropped: var(--hubble-ui-color-red);
  --hubble-ui-verdict-forwarded: var(--hubble-ui-color-green);

  /* Nav Sidebar | Policies Section */
  --hubble-ui-navsidebar-policies-outer: var(--hubble-ui-text-primary);
  --hubble-ui-navsidebar-policies-title: var(--hubble-ui-text-secondary);
  --hubble-ui-navsidebar-policies-switcher-note: #8dacbd;
  --hubble-ui-navsidebar-policies-switcher-note-active: #2689c1;
  --hubble-ui-navsidebar-policies-item: #bec4c7;
  --hubble-ui-navsidebar-policies-item-active: #000;
  --hubble-ui-navsidebar-policies-item-inactive-hover: #333;
  --hubble-ui-navsidebar-policies-filtersbanner-bg: #ecf5fb;
  --hubble-ui-navsidebar-policies-filtersbanner-bg-active: #ecf5fb;
  --hubble-ui-navsidebar-policies-filtersbanner-border-active: #1477ae;
  --hubble-ui-navsidebar-policies-filtersbanner-text: #1477ae;

  /* Nav Sidebar | Process Tree Section | Logs Upload */
  --hubble-ui-navsidebar-ptree-upload-btn: #182026;
  --hubble-ui-navsidebar-ptree-upload-help-text: #4e92b9;
  --hubble-ui-navsidebar-ptree-upload-help-text-hover: #4581a4;
  --hubble-ui-navsidebar-ptree-upload-help-icon: #4e92b9;
  --hubble-ui-navsidebar-ptree-upload-help-icon-hover: #4581a4;
  --hubble-ui-navsidebar-ptree-upload-help-cmd-border: #d1deea;

  /* Nav Sidebar | File Upload */
  --hubble-ui-svcmap-upload-help: #b2a9ab;
  --hubble-ui-svcmap-upload-help-hover: #7f7b7c;
  --hubble-ui-svcmap-upload-help-border: #dfeef6;

  /* Nav Sidebar | Status Center Handle */
  --hubble-ui-status-circle-green-light: #77d31b;
  --hubble-ui-status-circle-green-dark: #5cb800;
  --hubble-ui-status-circle-red-light: #ff3c3c;
  --hubble-ui-status-circle-red-dark: #e40000;
  --hubble-ui-connectionindicator-label: #7c8389;
  --hubble-ui-connectionindicator-flows-nodes-label: #5c7080;
  --hubble-ui-connectionindicator-color-idle: #c7c7c7;
  --hubble-ui-connectionindicator-color-receiving: #71bd25;
  --hubble-ui-connectionindicator-color-reconnecting: #71bd25;
  --hubble-ui-connectionindicator-color-stopped: #71bd25;
  --hubble-ui-statuscenterhandle-border: var(--hubble-ui-border);
  --hubble-ui-statuscenterhandle-caption: var(--hubble-ui-text-primary);
  --hubble-ui-statuscenterhandle-critical-bg: #e98585;
  --hubble-ui-statuscenterhandle-critical-border: #df6363;
  --hubble-ui-statuscenterhandle-critical-text: #fff7f7;

  /* Nav Sidebar | Status Center Window */
  --hubble-ui-statuscenterwindow-bg: #f8f9fa;
  --hubble-ui-statuscenterwindow-title: var(--hubble-ui-text-secondary);
  --hubble-ui-statuscenterwindow-no-entries: var(--hubble-ui-text-tertiary);
  --hubble-ui-statuscenterwindow-logentry-bg: #cbe0ec;
  --hubble-ui-statuscenterwindow-logentry-icon-bg: #cbe0ec;
  --hubble-ui-statuscenterwindow-intent-none-bg: #f7f7f7;
  --hubble-ui-statuscenterwindow-intent-none-border: #b7b7b7;
  --hubble-ui-statuscenterwindow-intent-none: #818181;
  --hubble-ui-statuscenterwindow-intent-critical-bg: #fff6f5;
  --hubble-ui-statuscenterwindow-intent-critical-error-highlight-bg: #f9f1f1;
  --hubble-ui-statuscenterwindow-intent-critical-border: #f8bab6;
  --hubble-ui-statuscenterwindow-intent-critical: #f3574d;
  --hubble-ui-statuscenterwindow-component-bg: #eaf7ff;
  --hubble-ui-statuscenterwindow-component-border: #b6c9cf;
  --hubble-ui-statuscenterwindow-component: var(--hubble-ui-text-tertiary);

  /* Nav Sidebar | Misc */
  --hubble-ui-sidebar-error-callout-bg: #f4e6e8;
  --hubble-ui-sidebar-error-callout-text: #b23133;

  /* Dashboard */
  --hubble-ui-dashboard-bg: var(--hubble-ui-background);
  --hubble-ui-dashboard-hubblestatus-head: #8a969f;
  --hubble-ui-dashboard-namespaces-description: #a6a6a6;
  --hubble-ui-dashboard-hubblestatus-border: var(--hubble-ui-border);
  --hubble-ui-dashboard-hubblestatus-enabled: #399604;
  --hubble-ui-dashboard-hubblestatus-disabled: #f54034;
  --hubble-ui-dashboard-hubblestatus-muted: #6f7d87;
  --hubble-ui-dashboard-hubblestatus-mini-info: #687177;

  /* Map */
  --hubble-ui-map-bg: linear-gradient(
    180deg,
    var(--hubble-ui-background-tertiary-1) 0%,
    var(--hubble-ui-background-tertiary-2) 100%
  );
  --hubble-ui-svcmap-boxshadow: inset 0 0 23px rgba(8, 44, 65, 0.12);
  --hubble-ui-svcmap-filter-input-shadow: 0 0 23px rgba(8, 44, 65, 0.12);
  --hubble-ui-svcmap-traffic-forwarded: #31a413;
  --hubble-ui-svcmap-traffic-dropped: #c50000;
  --hubble-ui-svcmap-feet-arrow-outer: #ebeef0;
  --hubble-ui-svcmap-arrow-handle: #acb1b8;

  /* Search Bar */
  --hubble-ui-searchbar-add-filter-btn-bg-hover: #dee4e8;
  --hubble-ui-searchbar-bg: #fff;
  --hubble-ui-searchbar-border: #edf0f3;
  --hubble-ui-searchbar-boxshadow: inset 0px 1px 4px rgba(28, 41, 49, 0.1);
  --hubble-ui-searchbar-clear-filter-btn-hover: #44535e;
  --hubble-ui-searchbar-container-bg: #fff;
  --hubble-ui-searchbar-filter-bg: #eaeef1;
  --hubble-ui-searchbar-filter-bg-focus: #c2d0db;
  --hubble-ui-searchbar-filter-bg-hover: #dee4e8;
  --hubble-ui-searchbar-filter-tag: #92939f;
  --hubble-ui-searchbar-filter-tag-border: #cad0d6;
  --hubble-ui-searchbar-filter-tag-icon: #5c7080;
  --hubble-ui-searchbar-filter-tag-direction-border: #fff;
  --hubble-ui-searchbar-filter-tag-direction-bg: #fff;
  --hubble-ui-searchbar-filter-tag-direction-from: #468706;
  --hubble-ui-searchbar-filter-tag-direction-to: #065a8d;
  --hubble-ui-searchbar-filter-tag-kind: #000;
  --hubble-ui-searchbar-filter-tag-meta: #5c7080;
  --hubble-ui-searchbar-filter-tag-query: #000;
  --hubble-ui-searchbar-filter-tag-separator: #5c7080;
  --hubble-ui-searchbar-form-add-filter-group-btn-bg: #f6f9fc;
  --hubble-ui-searchbar-form-add-filter-group-btn-border: #d1deea;
  --hubble-ui-searchbar-form-bg: #fff;
  --hubble-ui-searchbar-form-border: #2c3e4a;
  --hubble-ui-searchbar-form-checkbox-bg: #fff;
  --hubble-ui-searchbar-form-checkbox-border: gray;
  --hubble-ui-searchbar-form-checkbox-checkmark: #137cbd;
  --hubble-ui-searchbar-form-direction-btn-bg: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #e9eef2;
  --hubble-ui-searchbar-form-direction-btn-bg-active: #dde7ee;
  --hubble-ui-searchbar-form-direction-btn-border-active: #b3b9be;
  --hubble-ui-searchbar-form-direction-btn-border-right: #b8ccdc;
  --hubble-ui-searchbar-form-direction-btn-boxshadow: 0px 0px 0px 0px rgba(16, 22, 26, 0.2) inset,
    0px -1px 1px 0px rgba(16, 22, 26, 0.1) inset;
  --hubble-ui-searchbar-form-direction-btn-boxshadow-active: 0px 1px 2px 0px rgba(16, 22, 26, 0.2)
      inset,
    0px 0px 0px 0px rgba(16, 22, 26, 0.2) inset;
  --hubble-ui-searchbar-form-direction-btn-group-border: #c6cace;
  --hubble-ui-searchbar-form-dropshadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  --hubble-ui-searchbar-form-input-bg: #fff;
  --hubble-ui-searchbar-form-input-border: #cad0d6;
  --hubble-ui-searchbar-form-input-boxshadow: 0 1px 4px 0 rgba(28, 41, 49, 0.1) inset;
  --hubble-ui-searchbar-form-input-text: #000;
  --hubble-ui-searchbar-form-label: #000;
  --hubble-ui-searchbar-form-select-bg: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #e9eef2;
  --hubble-ui-searchbar-form-select-bg-hover: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0005) 100%
    ),
    #e9eef2;
  --hubble-ui-searchbar-form-select-border: #c6cace;
  --hubble-ui-searchbar-form-select-box-shadow: 0 0 0 0 rgba(16, 22, 26, 0.2) inset,
    0 -1px 1px 0 rgba(16, 22, 26, 0.1) inset;
  --hubble-ui-searchbar-form-select-item-bg: #f5f5f5;
  --hubble-ui-searchbar-form-select-item-bg-highlighted: rgba(143, 153, 168, 0.15);
  --hubble-ui-searchbar-form-select-item-bg-selected: rgba(45, 114, 210, 0.1);
  --hubble-ui-searchbar-form-select-item-filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.16));
  --hubble-ui-searchbar-form-select-text: #182026;
  --hubble-ui-searchbar-form-select-text-highlighted: #1c2127;
  --hubble-ui-searchbar-form-select-text-selected: #215db0;
  --hubble-ui-searchbar-operator-and-bg: #00ab97;
  --hubble-ui-searchbar-operator-and-color: #fff;
  --hubble-ui-searchbar-operator-not-color: #d00;
  --hubble-ui-searchbar-operator-or-bg: #8bb11f;
  --hubble-ui-searchbar-operator-or-color: #fff;

  /* Top Bar */
  --hubble-ui-topbar-bg: #fff;
  --hubble-ui-topbar-border: #dce0e3;
  --hubble-ui-topbar-boxshadow: 0 0 34px rgba(0, 0, 0, 0.16);
  --hubble-ui-topbar-icon: #5c7080;
  --hubble-ui-topbar-httpstatus-note: #666;
  --hubble-ui-topbar-flowsagg-note: #666;
  --hubble-ui-topbar-filter-verdict-dropped: #e26d66;
  --hubble-ui-topbar-filter-verdict-forwarded: #54a82d;
  --hubble-ui-topbar-filter-visual-enabled: #147fbc;

  /* Card */
  --hubble-ui-card-boxshadow: none;
  --hubble-ui-card-boxshadow-hover: #{$pt-elevation-shadow-3};
  --hubble-ui-card-boxshadow-active: #{$pt-elevation-shadow-1};

  /* Service Map | Card */
  --hubble-ui-svcmap-card-bg-primary: rgba(251, 251, 251, 0.85);
  --hubble-ui-svcmap-card-bg-secondary: #f6f6f6;
  --hubble-ui-svcmap-card-httpendpt-bg: #fff;
  --hubble-ui-svcmap-card-label-bg: #eaeaea;
  --hubble-ui-svcmap-card-nozzle-bg: #e3eaee;
  --hubble-ui-svcmap-card-border: #acb1b8;
  --hubble-ui-svcmap-card-container-border: #d4d4d4;
  --hubble-ui-svcmap-card-divider: #e4e4e4;
  --hubble-ui-svcmap-card-text-primary: #000;
  --hubble-ui-svcmap-card-label-text: #929292;

  // EndpointHeader
  --hubble-ui-svcmap-card-endpt-title: #303030;
  --hubble-ui-svcmap-card-endpt-subtitle: #969696;
  --hubble-ui-svcmap-card-endpt-connectors-border: #e4e4e4;
  --hubble-ui-svcmap-card-endpt-connectors-active: #137cbd;

  // AccessPoint
  --hubble-ui-svcmap-card-port: #848484;
  --hubble-ui-svcmap-card-protocol: #000;
  --hubble-ui-svcmap-card-dot-bg: #c8cdd3;
  --hubble-ui-svcmap-card-endpoints-limit: #929a9f;

  // HTTP Endpoint
  --hubble-ui-svcmap-card-httpendpt-border: #d4d4d4;
  --hubble-ui-svcmap-card-httpendpt-connector-circle-border: var(--hubble-ui-color-purple);
  --hubble-ui-svcmap-card-httpendpt-connector-circle-outer: var(--hubble-ui-color-purple);
  --hubble-ui-svcmap-card-httpendpt-connector-circle-inner: #d6c1ff;
  --hubble-ui-svcmap-card-httpendpt-connector-method: var(--hubble-ui-color-purple);

  /* Service Map | Namespace Backplate */
  --hubble-ui-svcmap-namespacebackplate-rect-fill: rgba(236, 238, 240, 0.48);
  --hubble-ui-svcmap-namespacebackplate-rect-stroke: #9ba1a8;
  --hubble-ui-svcmap-namespacebackplate-text: #58626d;

  /* Service Map | Flows Chart */
  --hubble-ui-flowschart-spinner-wrapper-bg: rgba(255, 255, 255, 0.7);
  --hubble-ui-flowschart-tooltip-bg: #fff;
  --hubble-ui-flowschart-yaxis-label: #808080;
  --hubble-ui-flowschart-yaxis-label-link: #a2a2a2;
  --hubble-ui-flowschart-yaxis-label-link-hover: #666;

  /* Panel */
  --hubble-ui-panel-bg: var(--hubble-ui-background);
  --hubble-ui-panel-boxshadow: 0px 0px 15px rgba(0, 0, 0, 0.16);

  /* DragPanel */

  // Service Map
  --hubble-ui-svcmap-dragpanel-bg-hover: #f6f6f6;
  --hubble-ui-svcmap-dragpanel-bg-active: #e7e7e7;

  // Network Policies
  --hubble-ui-netpol-dragpanel-bg-hover: #f6f6f6;
  --hubble-ui-netpol-dragpanel-bg-active: #e7e7e7;

  /* Flows Table */
  --hubble-ui-flowstable-bg: var(--hubble-ui-background);
  --hubble-ui-flowstable-head-bg: #f5fafd;
  --hubble-ui-flowstable-row-odd-bg: #fff;
  --hubble-ui-flowstable-row-even-bg: #f2f4f6;
  --hubble-ui-flowstable-row-bg-hover: #eff8fd;
  --hubble-ui-flowstable-row-bg-selected: #eff4f6;
  --hubble-ui-flowstable-head-border: #ebeef1;
  --hubble-ui-flowstable-row-border: #ebeef1;
  --hubble-ui-flowstable-head: #58666b;
  --hubble-ui-flowstable-row: #303030;
  --hubble-ui-flowstable-row-selected: #000;
  --hubble-ui-flowstable-subtitle: #7b7b7b;
  --hubble-ui-flowstable-subtitle-selected: #58666b;
  --hubble-ui-flowstable-method: var(--hubble-ui-text-primary);
  --hubble-ui-flowstable-direction: #6e6e6e;
  --hubble-ui-flowstable-ymd: #999;
  --hubble-ui-flowstable-emptyval: #999;

  // TODO: Update with color library
  --hubble-ui-flowstable-verdict-fwd: #288f0e;
  --hubble-ui-flowstable-verdict-drop: #d2443b;

  /* Flows Table | Sidebar */
  --hubble-ui-flowstable-sidebar-bg: #fff;
  --hubble-ui-flowstable-sidebar-boxshadow: 0 22px 22px rgba(0, 0, 0, 0.25);
  --hubble-ui-flowstable-sidebar-border: var(--hubble-ui-border-selected);
  --hubble-ui-flowstable-sidebar-hr: #ebeef1;
  --hubble-ui-flowstable-sidebar-header: var(--hubble-ui-text-secondary);
  --hubble-ui-flowstable-sidebar-title: #303030;
  --hubble-ui-flowstable-sidebar-text: #7b7b7b;
  --hubble-ui-flowstable-sidebar-link-decor: #b3b3b3;
  --hubble-ui-flowstable-sidebar-link-hover: #5e5e5e;
  --hubble-ui-flowstable-sidebar-link-decor-hover: #929292;
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-decor: #70c748;
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-hover: #3d811d;
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-decor-hover: #54a82d;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-decor: #e77878;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-hover: #b14b4b;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-decor-hover: #d46363;
  --hubble-ui-flowstable-sidebar-icon-close-bg: rgba(255, 255, 255, 0.5);
  --hubble-ui-flowstable-sidebar-icon-close: #c2c2c2;
  --hubble-ui-flowstable-sidebar-icon-close-hover: #a0a0a0;

  /* Network Policies | Map | Card */
  --hubble-ui-netpol-card-bg-primary: rgba(255, 255, 255, 0.85);
  --hubble-ui-netpol-card-boxshadow-color: rgba(23, 49, 65, 0.13);
  --hubble-ui-netpol-card-boxshadow-hover-clickable-color: rgba(23, 49, 65, 0.3);

  /* Network Policies | Map | Policy Card */
  --hubble-ui-netpol-card-bg-active: #e5f4ff;
  --hubble-ui-netpol-card-border-active: #1d5e8c;
  --hubble-ui-netpol-card-header-title: #303030;
  --hubble-ui-netpol-card-header-subtitle: #778187;
  --hubble-ui-netpol-card-header-title-subtitle-active: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-card-endpt-selector: #1d5e8c;
  --hubble-ui-netpol-card-endpt-selector-border: #dbe7ef;
  --hubble-ui-netpol-card-endpt-item-bg: #fff;
  --hubble-ui-netpol-card-endpt-item-bg-active: #e5f4ff;
  --hubble-ui-netpol-card-endpt-item-bg-active-hover: #d4eeff;
  --hubble-ui-netpol-card-endpt-item-supported-bg-hover: #efefef;
  --hubble-ui-netpol-card-endpt-item-border: #e7e7e7;
  --hubble-ui-netpol-card-endpt-item-border-active: #1d5e8c;
  --hubble-ui-netpol-card-endpt-item: #000;
  --hubble-ui-netpol-card-endpt-item-active: #fff;
  --hubble-ui-netpol-card-endpt-item-active-hover: #fff; // TODO
  --hubble-ui-netpol-card-endpt-item-icon-cidr-excl: #5c7080;
  --hubble-ui-netpol-card-inner-endpt-bg-active: #114163;
  --hubble-ui-netpol-card-inner-endpt-bg-hover: #114163;
  --hubble-ui-netpol-card-inner-endpt-border-active: #114163;
  --hubble-ui-netpol-card-inner-endpt-border-hover: #114163;
  --hubble-ui-netpol-card-inner-endpt-active: #fff;
  --hubble-ui-netpol-card-inner-endpt-hover: #fff;
  --hubble-ui-netpol-card-connectors-btns: #d94c4c;
  --hubble-ui-netpol-card-connectors-btns-hover: #d94c4c;
  --hubble-ui-netpol-card-connectors-btns-allow: #288f0e;
  --hubble-ui-netpol-card-connectors-btns-allow-hover: #288f0e;
  --hubble-ui-netpol-card-ruleselect-title: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-card-ruleselect-example: #8a969f;
  --hubble-ui-netpol-card-matchexpr-bg: #eff4f7;
  --hubble-ui-netpol-card-matchexpr-label: #8a969f;
  --hubble-ui-netpol-card-matchexpr-label-post-require: rgb(139, 61, 61);
  --hubble-ui-netpol-card-matchexpr-del-btn: #fff;
  --hubble-ui-netpol-card-matchexpr-del-btn-bg: #ff594f;
  --hubble-ui-netpol-card-matchexpr-del-btn-bg-hover: #c5573f;
  --hubble-ui-netpol-card-crudpopover-title: #444;
  --hubble-ui-netpol-card-crudpopover-label: #435d71;
  --hubble-ui-netpol-card-crudpopover-label-req: rgb(139, 61, 61);
  --hubble-ui-netpol-card-crudpopover-label-note: #444;
  --hubble-ui-netpol-card-crudpopover-warning-bg: #f9ecdf;
  --hubble-ui-netpol-card-crudpopover-warning: #bf7326;
  --hubble-ui-netpol-card-crudpopover-error-bg: #f9dfdf;
  --hubble-ui-netpol-card-crudpopover-error: #bf2626;

  /* Network Policy YAML Panel */
  --hubble-ui-yamlpanel-bg: #fdfdfd;
  --hubble-ui-yamlpanel-border: #d1deea;
  --hubble-ui-yamlpanel-dialog-title: var(--hubble-ui-text-primary);
  --hubble-ui-yamlpanel-dialog-code-bg: #fff;
  --hubble-ui-yamlpanel-dialog-code-border: #dbe7ef;
  --hubble-ui-yamlpanel-dialog-code: #1c2127;
  --hubble-ui-yamleditor-highlight-bg: rgba(198, 238, 204, 0.5);
  --hubble-ui-yamlpanel-unsupported-warning: #000;
  --hubble-ui-yamlpanel-unsupported-warning-bg: #f5eee7;
  --hubble-ui-yamlpanel-policyrating-title: #6c7986;

  /* Network Policies | Policies Panel */
  --hubble-ui-policiespanel-bg: var(--hubble-ui-background);
  --hubble-ui-policiespanel-gutter-bg: var(--hubble-ui-background);
  --hubble-ui-policiespanel-gutter-bg-hover: #eee;
  --hubble-ui-policiespanel-settings-label: #8a969f;

  /* Network Policies | Hubble Panel */
  --hubble-ui-hubblepanel-promo-title: #182026;
  --hubble-ui-hubblepanel-promo-descr: #6c7986;
  --hubble-ui-hubblepanel-help: #000;
  --hubble-ui-hubblepanel-help-cmd-bg: #edf4fa;
  --hubble-ui-hubblepanel-help-cmd: #4c6b82;
  --hubble-ui-hubblepanel-flows-title: #8a969f;
  --hubble-ui-hubblepanel-flowstable-border: #d1deea;

  /* Network Policies | Policies Panel | Policy Rating [Inactive] */
  --hubble-ui-netpol-rating-title: #000;
  --hubble-ui-netpol-rating-item-border: #d1deea;
  --hubble-ui-netpol-rating-item: #000;
  --hubble-ui-netpol-rating-item-kind-bg: #fff;
  --hubble-ui-netpol-rating-item-point-bg: #fff;
  --hubble-ui-netpol-rating-item-total-kind: #365b70;

  /* Network Policies | Slack Button */
  --hubble-ui-netpol-slack-label: #778187;
  --hubble-ui-netpol-slack-label-hover: #444;
  --hubble-ui-netpol-slack-btn-text: #000;
  --hubble-ui-netpol-slack-btn-bg: #fff;
  --hubble-ui-netpol-slack-btn-bg-hover: #f7f7f7;
  --hubble-ui-netpol-slack-btn-boxshadow-1: rgba(27, 57, 77, 0.11);
  --hubble-ui-netpol-slack-btn-boxshadow-2: rgba(16, 22, 26, 0.1);

  /* Network Policies | Policy Assistant */
  --hubble-ui-netpol-asst-card-h1: #000;
  --hubble-ui-netpol-asst-card-h2: #000;
  --hubble-ui-netpol-asst-card-h3: #365b70;
  --hubble-ui-netpol-asst-card-descr: #778187;
  --hubble-ui-netpol-asst-card-blockquote-bg: rgb(240, 240, 208);
  --hubble-ui-netpol-asst-card-pre: #efefef;
  --hubble-ui-netpol-asst-card-action-border: #e4ecf1;
  --hubble-ui-netpol-asst-card-action-el: #000;
  --hubble-ui-netpol-asst-rating-item-bg: #d1deea;
  --hubble-ui-netpol-asst-rating-item-colored-bg: #2689c1;

  /* Network Policies | Policy Suggestions */
  --hubble-ui-netpol-rec-card-bg: #fff;
  --hubble-ui-netpol-rec-card-boxshadow: rgba(22, 58, 83, 0.1);
  --hubble-ui-netpol-rec-card-violation: var(--hubble-ui-text-red);
  --hubble-ui-netpol-rec-card-rec: var(--hubble-ui-text-green);
  --hubble-ui-netpol-rec-card-title: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-rec-card-descr: var(--hubble-ui-text-secondary);

  /* Process Tree */
  --hubble-ui-ptree-text-primary: #000;
  --hubble-ui-ptree-text-secondary: #5c7080;
  --hubble-ui-ptree-text-tertiary: #78868e;
  --hubble-ui-ptree-container-text: #006fae;
  --hubble-ui-ptree-container-text-secondary: #4ea6d8;
  --hubble-ui-ptree-sus-text: #946a00;
  --hubble-ui-ptree-sus-text-secondary: #d6b86c;
  --hubble-ui-ptree-endpoint-port: #ff6838;
  --hubble-ui-ptree-expand-ctrl: #13a9ff;

  /* Process Tree | Lines + Dots */
  --hubble-ui-ptree-line: #b8b8b8;
  --hubble-ui-ptree-line-sus: #cf9500;
  --hubble-ui-ptree-dot: #b8b8b8;
  --hubble-ui-ptree-dot-sus: #cf9500;
  --hubble-ui-ptree-container-dot: #006fae;
  --hubble-ui-ptree-dest-arrow: #7591aa;

  /* Process Tree | Resize Handle */
  --hubble-ui-resize-handle-bg: #fff;
  --hubble-ui-resize-handle-bg-hover: #fbfbfe;
  --hubble-ui-resize-handle-dot-bg: #d1deea;

  /* Process Tree | Events Table */
  --hubble-ui-ptree-eventstable-header-bg: #fff;
  --hubble-ui-ptree-eventstable-header-text: var(--hubble-ui-ptree-text-secondary);
  --hubble-ui-ptree-eventstable-border: #dfeef6;
  --hubble-ui-ptree-eventstable-empty-text: #a4a4a4;
  --hubble-ui-ptree-eventstable-row-text: #303030;
  --hubble-ui-ptree-eventstable-row-bg-hover: #dfeef6;
  --hubble-ui-ptree-eventstable-row-border-bottom: #eaeef1;
  --hubble-ui-ptree-eventstable-sidebar-bg: #fff;
  --hubble-ui-ptree-eventstable-sidebar-border: #dfeef6;
  --hubble-ui-ptree-eventstable-sidebar-boxshadow: rgba(0, 0, 0, 0.25);
  --hubble-ui-ptree-eventstable-sidebar-header: #7b7b7b;
  --hubble-ui-ptree-eventstable-sidebar-close-btn: #c2c2c2;
  --hubble-ui-ptree-eventstable-sidebar-close-btn-hover: #a0a0a0;
  --hubble-ui-ptree-eventstable-sidebar-section-caption: #8a969f;
  --hubble-ui-ptree-eventstable-sidebar-labels-caption: #000;
  --hubble-ui-ptree-eventstable-sidebar-labels-content: #8f8f8f;

  /* Custom Widget | Button */
  --hubble-ui-ui-btn-bg: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.008) 100%
    ),
    #e9eef2;
  --hubble-ui-ui-btn-bg-hover: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0005) 100%
    ),
    #e9eef2;
  --hubble-ui-ui-btn-bg-active: #e4eef5;
  --hubble-ui-ui-btn-border: #c6cace;
  --hubble-ui-ui-btn-border-active: #b8ccdc;
  --hubble-ui-ui-btn-border-hover: #b8ccdc;
  --hubble-ui-ui-btn-boxshadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-ui-btn-boxshadow-active: inset 0 1px 2px rgba(16, 22, 26, 0.1);

  /* Blueprint Overrides */
  --hubble-ui-bp-bg-primary: #f8f9fa;
  --hubble-ui-bp-border: #c6cace;

  /* BP | Buttons */
  --hubble-ui-bp-btn-bg: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.0008) 100%
    ),
    #e9eef2;

  // --hubble-ui-bp-btn-bg-secondary: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0.5) 0%,
  //   rgba(255, 255, 255, 0.0001) 100%
  // ),
  // #394b59;
  --hubble-ui-bp-btn-bg-active: #dde7ee;
  --hubble-ui-bp-btn-bg-hover: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0005) 100%
    ),
    #e9eef2;
  --hubble-ui-bp-btn-bg-hover-2: rgba(143, 153, 168, 0.15);
  --hubble-ui-bp-btn-border: #c6cace;
  --hubble-ui-bp-btn-boxshadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-bp-btn-boxshadow-2: inset 0px 1px 2px rgba(16, 22, 26, 0.2);
  --hubble-ui-bp-btn-boxshadow-3: 0px 0px 0px rgba(16, 22, 26, 0.4);
  --hubble-ui-bp-btn-text: var(--hubble-ui-text-primary);
  --hubble-ui-bp-btn-text-secondary: #182026;

  /* BP | Button Group | Outlined | Policy Kind Selector */
  --hubble-ui-bp-btn-group-bg: rgba(246, 249, 252, 0.8);
  --hubble-ui-bp-btn-group-bg-active: #dde7ee;
  --hubble-ui-bp-btn-group-border-active: #b3b9be;
  --hubble-ui-bp-btn-group-boxshadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-bp-btn-group-boxshadow-active: inset 0px 1px 2px rgba(16, 22, 26, 0.2);
  --hubble-ui-bp-btn-intent-primary-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #137cbd;
  --hubble-ui-bp-btn-intent-primary-bg-hover: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #106ba3;
  --hubble-ui-bp-btn-intent-primary-bg-active: #0e5a8a;
  --hubble-ui-bp-btn-intent-primary-border: #1477ae;
  --hubble-ui-bp-btn-intent-primary-border-hover: #215db0;
  --hubble-ui-bp-btn-intent-primary-border-active: rgba(33, 93, 176, 0.5);
  --hubble-ui-bp-btn-intent-success-bg: linear-gradient(180deg, #438356 0%, #13642b 100%);
  --hubble-ui-bp-btn-intent-success-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #0d8050;
  --hubble-ui-bp-btn-intent-success-bg-active: #0a6640;
  --hubble-ui-bp-btn-intent-success-border: rgba(28, 110, 66, 0.6);
  --hubble-ui-bp-btn-intent-success-border-hover: rgba(28, 110, 66, 0.6);
  --hubble-ui-bp-btn-intent-success-border-active: rgba(28, 110, 66, 0.6);
  --hubble-ui-bp-btn-intent-warning-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #d9822b;
  --hubble-ui-bp-btn-intent-warning-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #bf7326;
  --hubble-ui-bp-btn-intent-warning-bg-active: #a66321;
  --hubble-ui-bp-btn-intent-warning-border: rgba(147, 86, 16, 0.6);
  --hubble-ui-bp-btn-intent-warning-bg-disabled: rgba(217, 130, 43, 0.5);
  --hubble-ui-bp-btn-intent-warning-disabled: rgba(255, 255, 255, 0.5);
  --hubble-ui-bp-btn-intent-danger-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #db3737;
  --hubble-ui-bp-btn-intent-danger-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 2.72%,
      rgba(255, 255, 255, 0.1) 98.83%
    ),
    #c23030;
  --hubble-ui-bp-btn-intent-danger-bg-active: #a82a2a;
  --hubble-ui-bp-btn-intent-danger-border: rgba(172, 47, 51, 0.6);
  --hubble-ui-bp-btn-intent-danger-border-hover: rgba(172, 47, 51, 0.6);
  --hubble-ui-bp-btn-intent-danger-border-active: rgba(172, 47, 51, 0.6);

  /* BP | Icons */
  --hubble-ui-bp-icon-annotation: #aabdce;
  --hubble-ui-bp-icon-caret-up: #5c7080;
  --hubble-ui-bp-icon-caret-vertical: #5c7080;
  --hubble-ui-bp-icon-chevron: #5c7080;
  --hubble-ui-bp-icon-chevron-down: #8a969f;
  --hubble-ui-bp-icon-cog: #5c7080;
  --hubble-ui-bp-icon-close: #5c7080;
  --hubble-ui-bp-icon-doc: #5c7080;
  --hubble-ui-bp-icon-upload: #5c7080;
  --hubble-ui-bp-icon-plus: #95afc8;
  --hubble-ui-bp-icon-time: #95afc8;

  /* BP | Menu Item */
  --hubble-ui-bp-menu-item-bg-active: rgba(143, 153, 168, 0.3);
  --hubble-ui-bp-menu-item-text-active: #1c2127;

  /* BP | Popover */
  --hubble-ui-statuscenter-bp-popover-dropshadow: 0px 20px 27px rgba(0, 0, 0, 0.07);
  --hubble-ui-polcard-bp-bg: #fff;

  /* BP | Time Picker */
  --hubble-ui-bp-timepicker-bg: #fff;
  --hubble-ui-bp-timepicker-border: #cad0d6;
  --hubble-ui-bp-timepicker-boxshadow: inset 0px 1px 4px rgba(28, 41, 49, 0.1);
  --hubble-ui-timepicker-icon-arrow: #5f6b7c;

  /* Misc */
  --hubble-ui-nodeconnectivity-bg: #fff;
  --hubble-ui-loading-overlay-label: #5c7080;
}

@mixin dark-theme {
  color-scheme: dark;

  /* System | Colors */
  --hubble-ui-color-white: #fff;
  --hubble-ui-color-black: #000;
  --hubble-ui-color-green: #2bd800;
  --hubble-ui-color-red: #ff594f;

  /* Colors | Service Map */
  --hubble-ui-color-purple: #af5fff;

  /* Colors | Process Tree */
  --hubble-ui-color-intent-warning: #be8e2f;

  /* System | Backgrounds */
  --hubble-ui-background: #23313d;
  --hubble-ui-background-secondary: #293742;
  --hubble-ui-background-secondary-selected: #2e3e4a;
  --hubble-ui-background-secondary-hover: #2b3b47;
  --hubble-ui-background-tertiary-1: #2b3b47;
  --hubble-ui-background-tertiary-2: #122229;

  /* System | Borders */
  --hubble-ui-border: #30404d;
  --hubble-ui-border-selected: #394d5c;

  /* System | Typography */
  --hubble-ui-text-primary: #fff;
  --hubble-ui-text-secondary: #a1b1bb;
  --hubble-ui-text-tertiary: #78868e;
  --hubble-ui-text-green: #2bd800;
  --hubble-ui-text-red: #ff594f;

  /* Nav Sidebar | Flows Verdicts | Flows Table */
  --hubble-ui-verdict-dropped: var(--hubble-ui-color-red);
  --hubble-ui-verdict-forwarded: var(--hubble-ui-color-green);

  // TODO: Add dark styles

  /* Nav Sidebar | Policies Section */

  --hubble-ui-navsidebar-policies-outer: var(--hubble-ui-text-primary);
  --hubble-ui-navsidebar-policies-title: var(--hubble-ui-text-secondary);
  --hubble-ui-navsidebar-policies-switcher-note: #8dacbd;
  --hubble-ui-navsidebar-policies-switcher-note-active: #3a84ae;
  --hubble-ui-navsidebar-policies-item: #999;
  --hubble-ui-navsidebar-policies-item-active: #fff;
  --hubble-ui-navsidebar-policies-item-inactive-hover: #fff;
  --hubble-ui-navsidebar-policies-filtersbanner-bg: #293742;
  --hubble-ui-navsidebar-policies-filtersbanner-bg-active: #293742;
  --hubble-ui-navsidebar-policies-filtersbanner-border-active: #293742;
  --hubble-ui-navsidebar-policies-filtersbanner-text: #8dacbd;

  /* Nav Sidebar | Process Tree Section | Logs Upload */
  --hubble-ui-navsidebar-ptree-upload-help-text: #78868e;
  --hubble-ui-navsidebar-ptree-upload-help-text-hover: #a1aab0;
  --hubble-ui-navsidebar-ptree-upload-help-icon: #78868e;
  --hubble-ui-navsidebar-ptree-upload-help-icon-hover: #a1aab0;
  --hubble-ui-navsidebar-ptree-upload-help-cmd-border: #1a2832;

  /* Nav Sidebar | File Upload */
  --hubble-ui-svcmap-upload-help: #b2a9ab;
  --hubble-ui-svcmap-upload-help-hover: #7f7b7c;
  --hubble-ui-svcmap-upload-help-border: #dfeef6;

  /* Nav Sidebar | Status Center Handle */
  --hubble-ui-status-circle-green-light: #77d31b;
  --hubble-ui-status-circle-green-dark: #5cb800;
  --hubble-ui-status-circle-red-light: #ff3c3c;
  --hubble-ui-status-circle-red-dark: #e40000;
  --hubble-ui-connectionindicator-label: #a1b1bb;
  --hubble-ui-connectionindicator-flows-nodes-label: var(--hubble-ui-text-secondary);
  --hubble-ui-connectionindicator-color-idle: #c7c7c7;
  --hubble-ui-connectionindicator-color-receiving: #77d31b;
  --hubble-ui-connectionindicator-color-reconnecting: #77d31b;
  --hubble-ui-connectionindicator-color-stopped: #77d31b;
  --hubble-ui-statuscenterhandle-border: #30404d;
  --hubble-ui-statuscenterhandle-caption: var(--hubble-ui-text-secondary);
  --hubble-ui-statuscenterhandle-critical-bg: #e98585;
  --hubble-ui-statuscenterhandle-critical-border: #df6363;
  --hubble-ui-statuscenterhandle-critical-text: #fff7f7;

  /* Nav Sidebar | Status Center Window */
  --hubble-ui-statuscenterwindow-bg: var(--hubble-ui-bp-bg-primary);
  --hubble-ui-statuscenterwindow-title: var(--hubble-ui-text-secondary);
  --hubble-ui-statuscenterwindow-no-entries: var(--hubble-ui-text-tertiary);
  --hubble-ui-statuscenterwindow-logentry-bg: #cbe0ec;
  --hubble-ui-statuscenterwindow-logentry-icon-bg: #cbe0ec;
  --hubble-ui-statuscenterwindow-intent-none-bg: #f7f7f7;
  --hubble-ui-statuscenterwindow-intent-none-border: #b7b7b7;
  --hubble-ui-statuscenterwindow-intent-none: var(--hubble-ui-text-tertiary);
  --hubble-ui-statuscenterwindow-intent-critical-bg: #fff6f5;
  --hubble-ui-statuscenterwindow-intent-critical-error-highlight-bg: rgba(205, 66, 70, 0.2);
  --hubble-ui-statuscenterwindow-intent-critical-border: #f8bab6;
  --hubble-ui-statuscenterwindow-intent-critical: #f3574d;
  --hubble-ui-statuscenterwindow-component-bg: #eaf7ff;
  --hubble-ui-statuscenterwindow-component-border: #b6c9cf;
  --hubble-ui-statuscenterwindow-component: var(--hubble-ui-text-tertiary);

  /* Nav Sidebar | Misc */
  --hubble-ui-sidebar-error-callout-bg: #472f35;
  --hubble-ui-sidebar-error-callout-text: #fa999c;

  /* Dashboard */
  --hubble-ui-dashboard-bg: var(--hubble-ui-background);
  --hubble-ui-dashboard-namespaces-description: #fff;
  --hubble-ui-dashboard-hubblestatus-head: #8a969f;
  --hubble-ui-dashboard-hubblestatus-border: var(--hubble-ui-border);
  --hubble-ui-dashboard-hubblestatus-enabled: var(--hubble-ui-color-green);
  --hubble-ui-dashboard-hubblestatus-disabled: var(--hubble-ui-color-red);
  --hubble-ui-dashboard-hubblestatus-muted: #6f7d87;
  --hubble-ui-dashboard-hubblestatus-mini-info: var(--hubble-ui-text-secondary);

  /* Map */
  --hubble-ui-map-bg: linear-gradient(
    180deg,
    var(--hubble-ui-background-tertiary-1) 0%,
    var(--hubble-ui-background-tertiary-2) 100%
  );
  --hubble-ui-svcmap-boxshadow: 0 0 23px rgba(8, 44, 65, 0.12);
  --hubble-ui-svcmap-filter-input-shadow: 0 0 23px rgba(8, 44, 65, 0.12);
  --hubble-ui-svcmap-traffic-forwarded: #31a413;
  --hubble-ui-svcmap-traffic-dropped: #c50000;
  --hubble-ui-svcmap-feet-arrow-outer: #264252;
  --hubble-ui-svcmap-arrow-handle: #acb1b8;

  /* Search Bar */
  --hubble-ui-searchbar-add-filter-btn-bg-hover: #2b3b47;
  --hubble-ui-searchbar-bg: linear-gradient(#242d35, #242d35);
  --hubble-ui-searchbar-border: #1e262b;
  --hubble-ui-searchbar-clear-filter-btn-hover: #839bac;
  --hubble-ui-searchbar-container-bg: #23313d;
  --hubble-ui-searchbar-filter-bg: #343b43;
  --hubble-ui-searchbar-filter-bg-focus: #0f1214;
  --hubble-ui-searchbar-filter-bg-hover: #424344;
  --hubble-ui-searchbar-filter-tag: #a1b1bb;
  --hubble-ui-searchbar-filter-tag-border: #212a31;
  --hubble-ui-searchbar-filter-tag-icon: #a1b1bb;
  --hubble-ui-searchbar-filter-tag-direction-bg: #374c5b;
  --hubble-ui-searchbar-filter-tag-direction-border: #374c5b;
  --hubble-ui-searchbar-filter-tag-direction-from: #2bd800;
  --hubble-ui-searchbar-filter-tag-direction-to: #2284c1;
  --hubble-ui-searchbar-filter-tag-kind: #fff;
  --hubble-ui-searchbar-filter-tag-meta: #a1b1bb;
  --hubble-ui-searchbar-filter-tag-query: #fff;
  --hubble-ui-searchbar-filter-tag-separator: #a1b1bb;
  --hubble-ui-searchbar-form-add-filter-group-btn-bg: #374c5b;
  --hubble-ui-searchbar-form-add-filter-group-btn-border: #212a31;
  --hubble-ui-searchbar-form-bg: #2c3e4a;
  --hubble-ui-searchbar-form-border: #2c3e4a;
  --hubble-ui-searchbar-form-checkbox-bg: linear-gradient(#3d4b58, #465360);
  --hubble-ui-searchbar-form-checkbox-border: #212a31;
  --hubble-ui-searchbar-form-checkbox-checkmark: #fff;
  --hubble-ui-searchbar-form-direction-btn-bg: linear-gradient(180deg, #465360 0%, #3a4754 100%);
  --hubble-ui-searchbar-form-direction-btn-bg-active: #202b33;
  --hubble-ui-searchbar-form-direction-btn-border-active: #202b33;
  --hubble-ui-searchbar-form-direction-btn-border-right: #212a31;
  --hubble-ui-searchbar-form-direction-btn-boxshadow: 0px 0px 0px 0px rgba(16, 22, 26, 0.2) inset,
    0px -1px 1px 0px rgba(16, 22, 26, 0.1) inset;
  --hubble-ui-searchbar-form-direction-btn-boxshadow-active: box-shadow: 0px 1px 2px 0px
    rgba(16, 22, 26, 0.2) inset;
  --hubble-ui-searchbar-form-direction-btn-dropshadow-active: drop-shadow(
    0px 0px 0px rgba(16, 22, 26, 0.6)
  );
  --hubble-ui-searchbar-form-direction-btn-group-border: #212a31;
  --hubble-ui-searchbar-form-dropshadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  --hubble-ui-searchbar-form-input-bg: linear-gradient(180deg, #242d35 0%, #242d35 100%);
  --hubble-ui-searchbar-form-input-border: #1e262b;
  --hubble-ui-searchbar-form-input-boxshadow: 0 1px 4px 0 rgba(28, 41, 49, 0.1) inset;
  --hubble-ui-searchbar-form-input-text: #78868e;
  --hubble-ui-searchbar-form-label: #a1b1bb;
  --hubble-ui-searchbar-form-select-bg: linear-gradient(180deg, #465360 0%, #3a4754 100%);
  --hubble-ui-searchbar-form-select-bg-hover: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0001) 100%
    ),
    #30404d;
  --hubble-ui-searchbar-form-select-border: #212a31;
  --hubble-ui-searchbar-form-select-box-shadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-searchbar-form-select-item-bg: #2c3e4a;
  --hubble-ui-searchbar-form-select-item-bg-highlighted: rgba(143, 153, 168, 0.15);
  --hubble-ui-searchbar-form-select-item-bg-selected: rgba(143, 153, 168, 0.3);
  --hubble-ui-searchbar-form-select-item-filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.16));
  --hubble-ui-searchbar-form-select-text: #fff;
  --hubble-ui-searchbar-form-select-text-highlighted: #fff;
  --hubble-ui-searchbar-form-select-text-selected: #fff;
  --hubble-ui-searchbar-operator-and-bg: #00ab97;
  --hubble-ui-searchbar-operator-and-color: #fff;
  --hubble-ui-searchbar-operator-not-color: #d00;
  --hubble-ui-searchbar-operator-or-bg: #8bb11f;
  --hubble-ui-searchbar-operator-or-color: #fff;

  /* Top Bar */
  --hubble-ui-topbar-bg: #000;
  --hubble-ui-topbar-border: #dce0e3;
  --hubble-ui-topbar-boxshadow: 0 0 34px rgba(0, 0, 0, 0.16);
  --hubble-ui-topbar-icon: #5c7080;
  --hubble-ui-topbar-httpstatus-note: #666;
  --hubble-ui-topbar-flowsagg-note: #666;
  --hubble-ui-topbar-filter-verdict-dropped: #e26d66;
  --hubble-ui-topbar-filter-verdict-forwarded: #54a82d;
  --hubble-ui-topbar-filter-visual-enabled: #147fbc;

  /* Card */
  --hubble-ui-card-boxshadow: #{$pt-dark-elevation-shadow-0};
  --hubble-ui-card-boxshadow-hover: #{$pt-dark-elevation-shadow-3};
  --hubble-ui-card-boxshadow-active: #{$pt-dark-elevation-shadow-1};

  /* Service Map | Card */
  --hubble-ui-svcmap-card-bg-primary: rgba(44, 62, 74, 0.85);
  --hubble-ui-svcmap-card-bg-secondary: #374c5b;
  --hubble-ui-svcmap-card-httpendpt-bg: #314452;
  --hubble-ui-svcmap-card-label-bg: #3c4f5e;
  --hubble-ui-svcmap-card-nozzle-bg: #3c5466;
  --hubble-ui-svcmap-card-border: #536d7b;
  --hubble-ui-svcmap-card-container-border: #42596a;
  --hubble-ui-svcmap-card-divider: #334755;
  --hubble-ui-svcmap-card-text-primary: #fff;
  --hubble-ui-svcmap-card-label-text: #a1b1bb;

  // EndpointHeader
  --hubble-ui-svcmap-card-endpt-title: var(--hubble-ui-text-primary);
  --hubble-ui-svcmap-card-endpt-subtitle: var(--hubble-ui-text-secondary);
  --hubble-ui-svcmap-card-endpt-connectors-border: #e4e4e4;
  --hubble-ui-svcmap-card-endpt-connectors-active: #137cbd;

  // AccessPoint
  --hubble-ui-svcmap-card-port: var(--hubble-ui-color-purple);
  --hubble-ui-svcmap-card-protocol: #fff;
  --hubble-ui-svcmap-card-dot-bg: #c8cdd3;
  --hubble-ui-svcmap-card-endpoints-limit: #000;

  // HTTP Endpoint
  --hubble-ui-svcmap-card-httpendpt-border: #42596a;
  --hubble-ui-svcmap-card-httpendpt-connector-circle-border: var(--hubble-ui-color-purple);
  --hubble-ui-svcmap-card-httpendpt-connector-circle-outer: var(--hubble-ui-color-purple);
  --hubble-ui-svcmap-card-httpendpt-connector-circle-inner: #d6c1ff;
  --hubble-ui-svcmap-card-httpendpt-connector-method: var(--hubble-ui-color-purple);

  /* Service Map | Namespace Backplate */
  --hubble-ui-svcmap-namespacebackplate-rect-fill: rgba(32, 73, 98, 0.1);
  --hubble-ui-svcmap-namespacebackplate-rect-stroke: #9ba1a8;
  --hubble-ui-svcmap-namespacebackplate-text: #fff;

  /* Service Map | Flows Chart */
  --hubble-ui-flowschart-spinner-wrapper-bg: rgba(35, 49, 61, 0.7);
  --hubble-ui-flowschart-tooltip-bg: #23313d;
  --hubble-ui-flowschart-yaxis-label: #a1b1bb;
  --hubble-ui-flowschart-yaxis-label-link: #a1b1bb;
  --hubble-ui-flowschart-yaxis-label-link-hover: #adc0cc;

  /* Panel */
  --hubble-ui-panel-bg: var(--hubble-ui-background);
  --hubble-ui-panel-boxshadow: 0px 0px 18px rgba(0, 0, 0, 0.16);

  /* DragPanel */

  // Service Map
  --hubble-ui-svcmap-dragpanel-bg-hover: #23313d;
  --hubble-ui-svcmap-dragpanel-bg-active: #212f3a;

  // Network Policies
  --hubble-ui-netpol-dragpanel-bg-hover: #23313d;
  --hubble-ui-netpol-dragpanel-bg-active: #212f3a;

  /* Flows Table */
  --hubble-ui-flowstable-bg: var(--hubble-ui-background);
  --hubble-ui-flowstable-row-head-bg: #2e3e4a;
  --hubble-ui-flowstable-row-odd-bg: #303030;
  --hubble-ui-flowstable-row-even-bg: #303030;
  --hubble-ui-flowstable-row-bg-hover: #314451;
  --hubble-ui-flowstable-row-bg-selected: #14222f;
  --hubble-ui-flowstable-head-border: #394d5c;
  --hubble-ui-flowstable-row-border: var(--hubble-ui-border);
  --hubble-ui-flowstable-head: #a1b1bb;
  --hubble-ui-flowstable-row: #fff;
  --hubble-ui-flowstable-row-selected: #fff;
  --hubble-ui-flowstable-subtitle: #a1b1bb;
  --hubble-ui-flowstable-subtitle-selected: #a1b1bb;
  --hubble-ui-flowstable-method: var(--hubble-ui-text-primary);
  --hubble-ui-flowstable-direction: #ececec;
  --hubble-ui-flowstable-ymd: #cacaca;
  --hubble-ui-flowstable-emptyval: #cacaca;

  // TODO: Update with color library
  --hubble-ui-flowstable-verdict-fwd: #2bd800;
  --hubble-ui-flowstable-verdict-drop: #ff594f;

  /* Flows Table | Sidebar */
  --hubble-ui-flowstable-sidebar-bg: var(--hubble-ui-background-secondary);
  --hubble-ui-flowstable-sidebar-boxshadow: 0 22px 22px rgba(0, 0, 0, 0.25);
  --hubble-ui-flowstable-sidebar-border: var(--hubble-ui-border-selected);
  --hubble-ui-flowstable-sidebar-hr: var(--hubble-ui-border);
  --hubble-ui-flowstable-sidebar-header: var(--hubble-ui-text-secondary);
  --hubble-ui-flowstable-sidebar-title: var(--hubble-ui-text-primary);
  --hubble-ui-flowstable-sidebar-text: var(--hubble-ui-text-secondary);
  --hubble-ui-flowstable-sidebar-link-decor: #b3b3b3;
  --hubble-ui-flowstable-sidebar-link-hover: #a8a8a8;
  --hubble-ui-flowstable-sidebar-link-decor-hover: #bdbdbd;

  // TODO: Update
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-decor: #70c748;
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-decor-hover: #54a82d;
  --hubble-ui-flowstable-sidebar-link-verdict-fwd-hover: #3d811d;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-decor: #e77878;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-decor-hover: #d46363;
  --hubble-ui-flowstable-sidebar-link-verdict-drop-hover: #b14b4b;
  --hubble-ui-flowstable-sidebar-icon-close-bg: rgba(35, 49, 61, 0.5);
  --hubble-ui-flowstable-sidebar-icon-close: #4f5a63;
  --hubble-ui-flowstable-sidebar-icon-close-hover: #a0a0a0;

  /* Network Policies | Map | Card */
  --hubble-ui-netpol-card-bg-primary: rgba(44, 62, 74, 0.85);
  --hubble-ui-netpol-card-boxshadow-color: rgba(23, 49, 65, 0.13);
  --hubble-ui-netpol-card-boxshadow-hover-clickable-color: rgba(23, 49, 65, 0.3);

  /* Network Policies | Map | Policy Card */
  --hubble-ui-netpol-card-bg-active: #202b33;
  --hubble-ui-netpol-card-border-active: #1d5e8c;
  --hubble-ui-netpol-card-header-title: #fff;
  --hubble-ui-netpol-card-header-subtitle: #78868e;
  --hubble-ui-netpol-card-header-title-subtitle-active: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-card-endpt-selector-bg: #374c5b;
  --hubble-ui-netpol-card-endpt-selector-border: #42596a;
  --hubble-ui-netpol-card-endpt-selector: #a1b1bb;
  --hubble-ui-netpol-card-endpt-item-bg: #374c5b;
  --hubble-ui-netpol-card-endpt-item-bg-active: #202b33;
  --hubble-ui-netpol-card-endpt-item-bg-active-hover: #202b33;
  --hubble-ui-netpol-card-endpt-item-supported-bg-hover: #202b33;
  --hubble-ui-netpol-card-endpt-item-border: #42596a;
  --hubble-ui-netpol-card-endpt-item-border-active: #394d5c;
  --hubble-ui-netpol-card-endpt-item: #a1b1bb;
  --hubble-ui-netpol-card-endpt-item-active: #fff;
  --hubble-ui-netpol-card-endpt-item-active-hover: #fff;
  --hubble-ui-netpol-card-endpt-item-icon-cidr-excl: #5c7080;
  --hubble-ui-netpol-card-inner-endpt-bg-active: #114163;
  --hubble-ui-netpol-card-inner-endpt-bg-hover: #114163;
  --hubble-ui-netpol-card-inner-endpt-border-active: #114163;
  --hubble-ui-netpol-card-inner-endpt-border-hover: #394d5c;
  --hubble-ui-netpol-card-inner-endpt-active: #fff;
  --hubble-ui-netpol-card-inner-endpt-hover: #a1b1bb;
  --hubble-ui-netpol-card-connectors-btns: #d94c4c;
  --hubble-ui-netpol-card-connectors-btns-hover: #d94c4c;
  --hubble-ui-netpol-card-connectors-btns-allow: #288f0e;
  --hubble-ui-netpol-card-connectors-btns-allow-hover: #288f0e;
  --hubble-ui-netpol-card-ruleselect-title: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-card-ruleselect-example: #8a969f;
  --hubble-ui-netpol-card-matchexpr-bg: #23323d;
  --hubble-ui-netpol-card-matchexpr-label: #8a969f;
  --hubble-ui-netpol-card-matchexpr-label-post-require: #ff594f;
  --hubble-ui-netpol-card-matchexpr-del-btn: #fff;
  --hubble-ui-netpol-card-matchexpr-del-btn-bg: #e36d52;
  --hubble-ui-netpol-card-matchexpr-del-btn-bg-hover: #c5573f;
  --hubble-ui-netpol-card-crudpopover-title: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-card-crudpopover-label: #a1b1bb;
  --hubble-ui-netpol-card-crudpopover-label-req: #ff594f;
  --hubble-ui-netpol-card-crudpopover-label-note: #444;
  --hubble-ui-netpol-card-crudpopover-warning-bg: #f9ecdf;
  --hubble-ui-netpol-card-crudpopover-warning: #bf7326;
  --hubble-ui-netpol-card-crudpopover-error-bg: #f9dfdf;
  --hubble-ui-netpol-card-crudpopover-error: #bf2626;

  /* Network Policy YAML Panel */
  --hubble-ui-yamlpanel-bg: #23313d;
  --hubble-ui-yamlpanel-border: #536d7b;
  --hubble-ui-yamlpanel-dialog-title: var(--hubble-ui-text-primary);
  --hubble-ui-yamlpanel-dialog-code-bg: #23323d;
  --hubble-ui-yamlpanel-dialog-code-border: #23323d;
  --hubble-ui-yamlpanel-dialog-code: #a1b1bb;
  --hubble-ui-yamleditor-highlight-bg: rgba(95, 126, 151, 0.475);

  // TODO: Add dark styles
  --hubble-ui-yamlpanel-unsupported-warning-bg: #000;
  --hubble-ui-yamlpanel-unsupported-warning: #f5eee7;
  --hubble-ui-yamlpanel-policyrating-title: #6c7986;

  /* Network Policies | Policies Panel */
  --hubble-ui-policiespanel-bg: var(--hubble-ui-background);

  // TODO: Add dark styles
  --hubble-ui-policiespanel-gutter-bg: var(--hubble-ui-background);
  --hubble-ui-policiespanel-gutter-bg-hover: #eee;
  --hubble-ui-policiespanel-settings-label: #8a969f;

  // TODO: Add dark styles

  /* Network Policies | Hubble Panel */
  --hubble-ui-hubblepanel-promo-title: #182026;
  --hubble-ui-hubblepanel-promo-descr: #6c7986;
  --hubble-ui-hubblepanel-help: #000;
  --hubble-ui-hubblepanel-help-cmd-bg: #edf4fa;
  --hubble-ui-hubblepanel-help-cmd: #4c6b82;
  --hubble-ui-hubblepanel-flows-title: #8a969f;
  --hubble-ui-hubblepanel-flowstable-border: #394d5c;

  // TODO: Add dark styles

  /* Network Policies | Policies Panel | Policy Rating [Inactive] */
  --hubble-ui-netpol-rating-title: #000;
  --hubble-ui-netpol-rating-item-border: #d1deea;
  --hubble-ui-netpol-rating-item: #000;
  --hubble-ui-netpol-rating-item-kind-bg: #fff;
  --hubble-ui-netpol-rating-item-point-bg: #fff;
  --hubble-ui-netpol-rating-item-total-kind: #365b70;

  // TODO: Add dark styles

  /* Network Policies | Slack Button */
  --hubble-ui-netpol-slack-label: #778187;
  --hubble-ui-netpol-slack-label-hover: #444;
  --hubble-ui-netpol-slack-btn-text: #000;
  --hubble-ui-netpol-slack-btn-bg: #fff;
  --hubble-ui-netpol-slack-btn-bg-hover: #f7f7f7;
  --hubble-ui-netpol-slack-btn-boxshadow-1: rgba(27, 57, 77, 0.11);
  --hubble-ui-netpol-slack-btn-boxshadow-2: rgba(16, 22, 26, 0.1);

  // TODO: Add dark styles

  /* Network Policies | Policy Assistant */
  --hubble-ui-netpol-asst-card-h1: #000;
  --hubble-ui-netpol-asst-card-h2: #000;
  --hubble-ui-netpol-asst-card-h3: #365b70;
  --hubble-ui-netpol-asst-card-descr: #778187;
  --hubble-ui-netpol-asst-card-blockquote-bg: rgb(240, 240, 208);
  --hubble-ui-netpol-asst-card-pre: #efefef;
  --hubble-ui-netpol-asst-card-action-border: #e4ecf1;
  --hubble-ui-netpol-asst-card-action-el: #000;

  // TODO: Add dark styles

  /* Network Policies | Policy Suggestions */
  --hubble-ui-netpol-rec-card-bg: #fff;
  --hubble-ui-netpol-rec-card-boxshadow: rgba(22, 58, 83, 0.1);
  --hubble-ui-netpol-rec-card-violation: var(--hubble-ui-text-red);
  --hubble-ui-netpol-rec-card-rec: var(--hubble-ui-text-green);
  --hubble-ui-netpol-rec-card-title: var(--hubble-ui-text-primary);
  --hubble-ui-netpol-rec-card-descr: var(--hubble-ui-text-secondary);

  /* Process Tree */
  --hubble-ui-ptree-text-primary: #fff;
  --hubble-ui-ptree-text-secondary: #a1b1bb;
  --hubble-ui-ptree-text-tertiary: #b4c1c9;
  --hubble-ui-ptree-container-text: #13a9ff;

  // --hubble-ui-ptree-container-text-secondary: #3a84ae;
  --hubble-ui-ptree-container-text-secondary: #75a9c6;
  --hubble-ui-ptree-sus-text: #ffa901;
  --hubble-ui-ptree-sus-text-secondary: #b69644;
  --hubble-ui-ptree-endpoint-port: #ff6838;
  --hubble-ui-ptree-expand-ctrl: #13a9ff;

  /* Process Tree | Lines + Dots */
  --hubble-ui-ptree-line: #4f5a63;
  --hubble-ui-ptree-line-sus: #ffa901;
  --hubble-ui-ptree-dot: #4f5a63;
  --hubble-ui-ptree-dot-sus: #ffa901;
  --hubble-ui-ptree-container-dot: #13a9ff;
  --hubble-ui-ptree-dest-arrow: #7591aa;

  /* Process Tree | Resize Handle */
  --hubble-ui-resize-handle-bg: #23313d;
  --hubble-ui-resize-handle-bg-hover: #2e3e4a;
  --hubble-ui-resize-handle-dot-bg: #c4c4c4;

  /* Process Tree | Events Table */
  --hubble-ui-ptree-eventstable-header-bg: #23313d;

  // --hubble-ui-ptree-eventstable-header-text:
  --hubble-ui-ptree-eventstable-border: #2c3d4c;
  --hubble-ui-ptree-eventstable-empty-text: #a4a4a4;
  --hubble-ui-ptree-eventstable-row-text: #fff;
  --hubble-ui-ptree-eventstable-row-bg-hover: #14222f;
  --hubble-ui-ptree-eventstable-row-border-bottom: #30404d;
  --hubble-ui-ptree-eventstable-sidebar-bg: var(--hubble-ui-background-secondary);
  --hubble-ui-ptree-eventstable-sidebar-border: #394d5c;
  --hubble-ui-ptree-eventstable-sidebar-boxshadow: rgba(0, 0, 0, 0.25);
  --hubble-ui-ptree-eventstable-sidebar-header: var(--hubble-ui-text-secondary);
  --hubble-ui-ptree-eventstable-sidebar-close-btn: #4f5a63;
  --hubble-ui-ptree-eventstable-sidebar-close-btn-hover: #a0a0a0;
  --hubble-ui-ptree-eventstable-sidebar-section-caption: var(--hubble-ui-text-tertiary);
  --hubble-ui-ptree-eventstable-sidebar-labels-caption: var(--hubble-ui-ptree-text-primary);
  --hubble-ui-ptree-eventstable-sidebar-labels-content: var(--hubble-ui-ptree-text-secondary);

  /* Custom Widget | Button */
  --hubble-ui-ui-btn-bg: linear-gradient(180deg, #465360 0%, #3a4754 100%), #465360;
  --hubble-ui-ui-btn-bg-active: #465360;
  --hubble-ui-ui-btn-bg-hover: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0001) 100%
    ),
    #30404d;
  --hubble-ui-ui-btn-border: #212a31;
  --hubble-ui-ui-btn-border-active: #212a31;
  --hubble-ui-ui-btn-border-hover: #212a31;
  --hubble-ui-ui-btn-boxshadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-ui-btn-boxshadow-active: inset 0 1px 2px rgba(16, 22, 26, 0.1);

  /* Blueprint Overrides */
  --hubble-ui-bp-bg-primary: #2c3e4a;
  --hubble-ui-bp-border: #212a31;

  /* BP | Buttons */
  --hubble-ui-bp-btn-bg: linear-gradient(180deg, #465360 0%, #3a4754 100%);
  --hubble-ui-bp-btn-bg-secondary: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.0001) 100%
    ),
    #394b59;
  --hubble-ui-bp-btn-bg-active: #202b33;
  --hubble-ui-bp-btn-bg-hover: linear-gradient(
      180deg,
      rgb(255, 255, 255, 5%) 0%,
      rgb(255, 255, 255, 0.0005%) 100%
    ),
    #30404d;
  --hubble-ui-bp-btn-bg-hover-2: rgba(143, 153, 168, 0.15);
  --hubble-ui-bp-btn-border: #212a31;
  --hubble-ui-bp-btn-boxshadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  --hubble-ui-bp-btn-boxshadow-2: inset 0px 1px 2px rgba(16, 22, 26, 0.2);
  --hubble-ui-bp-btn-boxshadow-3: 0px 0px 0px rgba(16, 22, 26, 0.4);
  --hubble-ui-bp-btn-text: var(--hubble-ui-text-primary);

  /* BP | Button Group | Outlined */
  --hubble-ui-bp-btn-group-bg: var(--hubble-ui-bp-btn-bg);
  --hubble-ui-bp-btn-group-bg-active: var(--hubble-ui-bp-btn-bg-active);
  --hubble-ui-bp-btn-group-border-active: #212a31;
  --hubble-ui-bp-btn-group-boxshadow-active: 0px 0px 0px rgba(16, 22, 26, 0.6),
    inset 0px 1px 2px rgba(16, 22, 26, 0.2);
  --hubble-ui-bp-btn-intent-primary-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #137cbd;
  --hubble-ui-bp-btn-intent-primary-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #106ba3;
  --hubble-ui-bp-btn-intent-primary-bg-active: #0e5a8a;
  --hubble-ui-bp-btn-intent-primary-border: #1477ae;
  --hubble-ui-bp-btn-intent-primary-border-hover: #215db0;
  --hubble-ui-bp-btn-intent-primary-border-active: rgba(33, 93, 176, 0.6);
  --hubble-ui-bp-btn-intent-success-bg: linear-gradient(180deg, #438356 0%, #13642b 100%);
  --hubble-ui-bp-btn-intent-success-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #0d8050;
  --hubble-ui-bp-btn-intent-success-bg-active: #0a6640;
  --hubble-ui-bp-btn-intent-success-border: #004315;
  --hubble-ui-bp-btn-intent-success-border-hover: #1c6e42;
  --hubble-ui-bp-btn-intent-success-border-active: #1c6e42;
  --hubble-ui-bp-btn-intent-warning-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #d9822b;
  --hubble-ui-bp-btn-intent-warning-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 2.73%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #bf7326;
  --hubble-ui-bp-btn-intent-warning-bg-active: #a66321;
  --hubble-ui-bp-btn-intent-warning-bg-disabled: rgba(217, 130, 43, 0.4954);
  --hubble-ui-bp-btn-intent-warning-disabled: rgba(255, 255, 255, 0.4953);
  --hubble-ui-bp-btn-intent-warning-border: #935610;
  --hubble-ui-bp-btn-intent-warning-border-hover: #935610;
  --hubble-ui-bp-btn-intent-warning-border-active: #935610;
  --hubble-ui-bp-btn-intent-danger-bg: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #db3737;
  --hubble-ui-bp-btn-intent-danger-bg-hover: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.0001) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #c23030;
  --hubble-ui-bp-btn-intent-danger-bg-active: #a82a2a;
  --hubble-ui-bp-btn-intent-danger-border: #ac2f33;
  --hubble-ui-bp-btn-intent-danger-border-hover: #ac2f33;
  --hubble-ui-bp-btn-intent-danger-border-active: #ac2f33;

  /* BP | Menu Item */
  --hubble-ui-bp-menu-item-bg-active: rgba(143, 153, 168, 0.3);
  --hubble-ui-bp-menu-item-bg-selected: rgba(143, 153, 168, 0.3);
  --hubble-ui-bp-menu-item-text-active: #fff;

  /* BP | Popover */
  --hubble-ui-polcard-bp-bg: #2c3e4a;
  --hubble-ui-statuscenter-bp-popover-dropshadow: 0px 20px 27px rgba(0, 0, 0, 0.07);
  --hubble-ui-bp-popover-boxshadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  --hubble-ui-bp-dialog-boxshadow: 0px 4px 25px rgba(0, 0, 0, 0.15);

  /* BP | Time Picker */
  --hubble-ui-bp-timepicker-bg: linear-gradient(180deg, #242d35 0%, #242d35 100%);
  --hubble-ui-bp-timepicker-border: #30404d;
  --hubble-ui-bp-timepicker-boxshadow: inset 0px 1px 4px rgba(28, 41, 49, 0.1);
  --hubble-ui-timepicker-icon-arrow: #abb3bf;

  /* BP | Day Picker */
  --hubble-ui-bp-daypicker-day-bg: rgba(143, 153, 168, 0.3);
  --hubble-ui-bp-daypicker-day-bg-selected: rgba(143, 153, 168, 0.6);
  --hubble-ui-bp-daypicker-day-bg-hover: rgba(143, 153, 168, 0.2);
  --hubble-ui-bp-daypicker-selected-range-bg: rgba(143, 153, 168, 0.3);
  --hubble-ui-bp-daypicker-selected-range-bg-hover: rgba(143, 153, 168, 0.6);

  /* BP | Icons */
  --hubble-ui-bp-icon-annotation: #d7dee5;
  --hubble-ui-bp-icon-cog: #b8ccdc;
  --hubble-ui-bp-icon-close: #5c7080;
  --hubble-ui-bp-icon-doc: #b8ccdc;
  --hubble-ui-bp-icon-upload: #b8ccdc;
  --hubble-ui-bp-icon-plus: #95afc8;
  --hubble-ui-bp-icon-chevron: #c1ccd5;
  --hubble-ui-bp-icon-chevron-down: #8a969f;
  --hubble-ui-bp-icon-caret-vertical: #c1ccd5;
  --hubble-ui-bp-icon-caret-up: #c1ccd5;

  /* Misc */
  --hubble-ui-nodeconnectivity-bg: #fff;
  --hubble-ui-loading-overlay-label: #a1b1bb;
}
